import { userConstants } from '../../constants';

const initialState = {
  loading: false,
  error: null,
  email: null,
  emailSubmitted: false
};

export function forgotPassword(state = initialState, action) {
  switch (action.type) {
    case userConstants.PASSWORD_RESET_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.PASSWORD_RESET_SUCCESS:
      return { ...state, loading: false, error: null, emailSubmitted: true, email: action.email };
    case userConstants.PASSWORD_RESET_FAILURE:
      return { ...state, loading: false, error: action.error };
    case userConstants.PASSWORD_RESET_SUBMIT_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.PASSWORD_RESET_SUBMIT_SUCCESS:
      return { ...state, loading: false, error: null };
    case userConstants.PASSWORD_RESET_SUBMIT_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state
  }
}

export const selectForgotPassword = (state) => state.forgotPassword;