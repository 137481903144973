import React from 'react';
import { useSelector } from 'react-redux';
import Checkout from '../../components/CheckoutForm/StripeCheckout';
import { Row, Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import ManagePaymentElement from '../../components/PaymentSettings/ManagePaymentElement';
import AccountSettingsNav  from '../../components/AccountSettingsNav/AccountSettingsNav'
import { selectBilling } from 'src/redux/modules/billingReducer';
import { selectPaymentAccounts } from 'src/redux/modules/memberReducer';

const PaymentSettingsContainer = () => {

  const paymentAccounts = useSelector(selectPaymentAccounts);
  const billingState = useSelector(selectBilling);

  return (
    <div className="container">
      <Row>
        <AccountSettingsNav/>
      </Row>
      <br/>
      <Row>
        { paymentAccounts.length > 0 &&
          <ManagePaymentElement/>
        }
        { paymentAccounts.length === 0 &&
        <Container>No payment accounts added.</Container>
        }
      </Row>
      <Modal isOpen={billingState.isPaymentModelOpen}> 
        <ModalHeader>Update credit card</ModalHeader>
        <ModalBody>
          <Checkout />
        </ModalBody>    
      </Modal>
    </div>
  );
}

export default PaymentSettingsContainer