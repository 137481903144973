import { useState, React } from 'react';
import { useSelector } from 'react-redux'
import InjectedCheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../config';
import { selectBilling } from 'src/redux/modules/billingReducer';
import { selectPlan } from '../../redux/modules/planReducer'

const Checkout = () => {

  const planState = useSelector(selectPlan);
  const billing = useSelector(selectBilling);
  const [stripePromise] = useState(() => loadStripe(config.stripePublishableKey))

  const plan = planState.availablePlans[billing.planIdToPurchase]
  
  let options = {};
  if (plan) {
    options = {
      mode: 'payment',
      currency: 'usd',
      amount: plan.priceInCents,
    };
    // if no plan, then we are updating a card
  }

  return (
    <div> 
      <Elements stripe={stripePromise} options={options}>
        <InjectedCheckoutForm />
      </Elements>
    </div>
  );
}

export default Checkout;