import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Form, FormGroup, Label, FormFeedback, Button, Card, CardBody, Spinner } from 'reactstrap';
import { userActions } from '../../actions';

const ChangePasswordForm = () => {
  const [localPasswordPair, setLocalPasswordPair] = useState({
    currentPassword: '',
    newPassword: ''
  });
  
  const [submitted, setSubmitted] = useState(false);
  const [loading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalPasswordPair(localPasswordPair => ({
      ...localPasswordPair,
      [name]: value
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true)

    if (localPasswordPair && localPasswordPair.currentPassword && localPasswordPair.newPassword) {
      dispatch(userActions.changePassword(localPasswordPair.currentPassword, localPasswordPair.newPassword));
    }
  }

  const invalidCurrentPassword = submitted && !localPasswordPair.currentPassword;
  const invalidNewPassword = submitted && !localPasswordPair.newPassword;

  return (
    <div>
      <Form onSubmit={handleSubmit} type="post">
        <Card body>
          <CardBody >
            <FormGroup row>
              <Label for="currentPassword">Current password</Label>
              <Input invalid={invalidCurrentPassword} name="currentPassword" id="currentPassword" type="Password" onChange={handleChange} />
              <FormFeedback invalid="{invalidCurrentPassword}">Enter current password.</FormFeedback>
            </FormGroup>
            <FormGroup row>
              <Label for="newPassword">New password</Label>
              <Input invalid={invalidNewPassword} name="newPassword" id="newPassword" type="Password" onChange={handleChange} />
              <FormFeedback invalid="{invalidNewPassword}">Enter new password.</FormFeedback>
            </FormGroup>
            <FormGroup row>
              <Button color="primary">Change Password</Button>
              {loading &&
                <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
              }
            </FormGroup>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}
export default ChangePasswordForm;