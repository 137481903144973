import React from 'react';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import { Container, Row, Col } from 'reactstrap';

const Register = () => {
  return (
    <div>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={10} sm={8} md={8} lg={8} xl={8}>
            <RegisterForm />
          </Col>
        </Row>
      </Container> 
    </div>
  );
}

export default Register;