import React from 'react';
import { Row } from 'reactstrap';
import SettingsForm from '../../components/SettingsForm/SettingsForm';
import AccountSettingsNav  from '../../components/AccountSettingsNav/AccountSettingsNav'

const Settings = () =>  {

  return (
    <div className="container">
      <Row>
        <AccountSettingsNav/>
      </Row>
      <br/>
      <Row>
        <SettingsForm/>
      </Row>
    </div>
  );
}

export default Settings;