import React from 'react';
import { Row } from 'reactstrap';
import ManageSubscriptionElement from '../../components/Subscription/ManageSubscriptionElement';
import AccountSettingsNav  from '../../components/AccountSettingsNav/AccountSettingsNav'

const ManageSubscriptionContainer = () => {

  return (
    <div className="container">
      <Row>
        <AccountSettingsNav/>
      </Row>
      <br/>
      <Row>
        <ManageSubscriptionElement/>
      </Row>
    </div>
  );
}

export default ManageSubscriptionContainer;