import { userConstants } from '../../constants';

const initialState = {
  virtualEmailConfig: null,
  loading: false,
  error: null,
};

export function virtualEmailConfig(state = initialState, action) {
  switch (action.type) {
    case userConstants.ENABLE_REPLY_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.DISABLE_REPLY_REQUEST:
      return { ...state, loading: true, error: null };  
    case userConstants.ENABLE_REPLY_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null };
    case userConstants.DISABLE_REPLY_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null };  
    case userConstants.ENABLE_REPLY_FAILURE:
      return { ...state, loading: false, error: action.error };
    case userConstants.DISABLE_REPLY_FAILURE:
      return { ...state, loading: false, error: action.error };  

    case userConstants.ENABLE_ATTACH_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.DISABLE_ATTACH_REQUEST:
      return { ...state, loading: true, error: null };  
    case userConstants.ENABLE_ATTACH_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null };
    case userConstants.DISABLE_ATTACH_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null };  
    case userConstants.ENABLE_ATTACH_FAILURE:
      return { ...state, loading: false, error: action.error };
    case userConstants.DISABLE_ATTACH_FAILURE:
      return { ...state, loading: false, error: action.error };  
    
    case userConstants.ENABLE_AUTO_CREATE_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.DISABLE_AUTO_CREATE_REQUEST:
      return { ...state, loading: true, error: null };  
    case userConstants.ENABLE_AUTO_CREATE_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null };
    case userConstants.DISABLE_AUTO_CREATE_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null };  
    case userConstants.ENABLE_AUTO_CREATE_FAILURE:
      return { ...state, loading: false, error: action.error };
    case userConstants.DISABLE_AUTO_CREATE_FAILURE:
      return { ...state, loading: false, error: action.error };  
    
        
    default:
      return state
  }
}

export const selectVirtualEmailConfig = (state) => state.virtualEmailConfig;