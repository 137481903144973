export function getPeriod(billingFrequency) {

  let period = '';

  if  (billingFrequency === 'YEARLY') {
    period = 'year';
  } else if (billingFrequency === 'MONTHLY') {
    period = 'month';
  }
  return period;
}