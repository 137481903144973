import React from 'react';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import { Container, Row, Col } from 'reactstrap';

const ForgotPassword = () =>  {

  return (
    <div>
      <Container>
        <Row>
          <Col sm="12">
            <br/>
            <ForgotPasswordForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ForgotPassword;