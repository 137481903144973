import { Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Register from "../../containers/Register/Register";
import Login from "../../containers/Login/Login";
import Forwarding from "../../containers/Forwarding/Forwarding"
import Home from "../../containers/Home/Home"
import Privacy from "../../containers/Privacy/Privacy"
import Terms from "../../containers/Terms/Terms"
import ChangePassword from "../../containers/ChangePassword/ChangePassword"
import RegisterDomainContainer from "../../containers/CustomDomain/RegisterDomainContainer"
import ForgotPassword from "../../containers/ForgotPassword/ForgotPassword"
import VerifyAccount from "../../containers/VerifyAccount/VerifyAccount"
import ManageSubscriptionContainer from "../../containers/ManageSubscription/ManageSubscriptionContainer"
import Settings from "../../containers/Settings/Settings"
import Footer from "../../containers/Footer/Footer"
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { NavLink as RRNavLink, useNavigate } from 'react-router-dom';
import { userActions } from '../../actions';
import { virtualEmailActions } from '../../actions';
import { userService } from '../../services/userService';
import logo from '../../logo.svg';
import logoNight from '../../logo.svg';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, NavbarToggler, Collapse } from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons';
import SubscriptionContainer from '../../containers/Subscribe/SubscriptionContainer';
import SubscriptionOptionsContainer from '../../containers/Subscribe/SubscriptionOptionsContainer';
import PaymentSettingsContainer from '../../containers/PaymentSettings/PaymentSettingsContainer'
import { selectAlert } from '../../redux/modules/alertReducer';
import { selectVerified } from '../../redux/modules/memberReducer';
import { selectSubscription } from '../../redux/modules/subscriptionReducer';
import { paths } from '../../constants'

library.add(faEnvelope, faKey);

export const PageNotFound = (props, context = {}) => {
  if (context.setStatus) {
    context.setStatus(404)
  }

  return (
    <div>
      <h1>
        Page not found
      </h1>
      <Link to="/">
        Go home
      </Link>
    </div>
  )
}
PageNotFound.contextTypes = {
  setStatus: PropTypes.func.isRequired
}

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = userService.isLoggedIn(); 
  const alert = useSelector(selectAlert);
  const isVerified = useSelector(selectVerified);
  const subscription = useSelector(selectSubscription);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      if (key.startsWith("utm")) {
        localStorage.setItem(key, value);
      }
    });
    if (isLoggedIn && location.pathname !== paths.login) {
      dispatch(virtualEmailActions.getSummary(false, navigate));
      dispatch(userActions.refreshAuth());
    }
  
  }, [dispatch, isLoggedIn, navigate, location]);

  let alertColor = "primary";
  if (alert && alert.type === "alert-danger") {
    alertColor = "danger";
  }

  const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
  const themedLogo = prefersDarkScheme.matches ? logoNight : logo

  let logoLink = isLoggedIn ? paths.forwarding : '/';

  return (
    <div className="defaultFont">
      <div>
        {alert && alert.message &&
          <Alert color={alertColor}>
            {alert.message} {alert.link && <Link to={alert.link}>{alert.linkText}</Link>}
          </Alert>
        }
      </div>

      <header>        
        <Navbar light expand="md">
          {isLoggedIn && !isVerified && <NavbarBrand><img width="100px" src={themedLogo} alt="ID Unseen" />&nbsp;</NavbarBrand>}
          {(!isLoggedIn || isVerified) && <NavbarBrand tag={RRNavLink} to={logoLink}><img width="100px" src={themedLogo} alt="ID Unseen" />&nbsp;</NavbarBrand>}
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            {isLoggedIn && isVerified &&
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <NavLink tag={RRNavLink} to={paths.forwarding}>Forwarding</NavLink>
                </NavItem>
                {!subscription.isCustomDomainSubscription && <NavItem>
                  <NavLink tag={RRNavLink} to={paths.subscriptionLandingPage}>Subscribe</NavLink>
                </NavItem>}
                <NavItem>
                  <NavLink tag={RRNavLink} to={paths.account}>Account</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={RRNavLink} to={paths.login}>Logout</NavLink>
                </NavItem>
              </Nav>
            }
            {isLoggedIn && !isVerified &&
              <Nav className="ms-auto" navbar>
                <NavItem>
                  <NavLink tag={RRNavLink} to={paths.login}>Logout</NavLink>
                </NavItem>
              </Nav>
            }
            {!isLoggedIn &&
              <Nav className="ms-auto" navbar>
                {location.pathname !== paths.login &&
                   <NavItem><NavLink tag={RRNavLink} to={paths.login}>Login</NavLink></NavItem>}
                {location.pathname !== paths.register &&
                  <NavItem><NavLink tag={RRNavLink} to={paths.register}>Register</NavLink></NavItem>}
              </Nav>
            }
          </Collapse>
        </Navbar>

      </header>
      <Routes>
        <Route path={paths.verifyEmail} element={<PrivateRoute component={VerifyAccount} />} />
        <Route path={paths.mailSettings} element={<PrivateRoute component={Settings} />} />
        <Route path={paths.subscriptionSettings} element={<PrivateRoute component={ManageSubscriptionContainer} />} />
        <Route path={paths.customDomainSubscribe} element={<PrivateRoute component={RegisterDomainContainer} />} />
        <Route path={paths.serviceSubscribe} element={<PrivateRoute component={SubscriptionContainer} />} />
        <Route path={paths.subscriptionLandingPage} element={<PrivateRoute component={SubscriptionOptionsContainer} />} />
        <Route path={paths.paymentSettings} element={<PrivateRoute component={PaymentSettingsContainer} />} />
        <Route path={paths.changePassword} element={<PrivateRoute component={ChangePassword} />} />
        <Route path={paths.account} element={<PrivateRoute component={Settings} />} />
        <Route path={paths.forwarding} element={<PrivateRoute component={Forwarding} />} />
        <Route path={paths.login} element={<Login />}  />
        <Route path={paths.register} element={<Register/>} />
        <Route path="/forgotPassword/" element={<ForgotPassword />} />
        <Route path="/terms-of-service/" element={<Terms/>} />
        <Route path="/privacy-policy/" element={<Privacy/>} />
        <Route exact path="/" element={<Home/>} />
      </Routes>

      <Footer />

    </div>
  );
};

function PrivateRoute({ component: Component, ...rest }) {
  const authed = userService.isLoggedIn(); 
  const location = useLocation();

  return authed ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export { App };