import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SubscriptionSetupElement from '../../components/Subscription/SubscriptionSetupElement'
import CheckoutSavedCardForm from '../../components/CheckoutForm/CheckoutSavedCardForm'
import Checkout from '../../components/CheckoutForm/StripeCheckout';
import { Alert } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import { getPeriod } from '../../helpers/timeUtil'
import { selectPlan } from 'src/redux/modules/planReducer';
import { selectSubscription } from 'src/redux/modules/subscriptionReducer';
import { selectPaymentAccounts } from 'src/redux/modules/memberReducer';
import { selectBilling } from 'src/redux/modules/billingReducer';


const SubscriptionContainer = () => {

  const billingState = useSelector(selectBilling);
  const plan = useSelector(selectPlan);
  const subscription = useSelector(selectSubscription);
  const paymentAccounts = useSelector(selectPaymentAccounts);

  const navigate = useNavigate();

  if (!plan.servicePlan) {
    return <div></div>
  }

  const priceInDollars = plan.servicePlan.priceInCents/100;
  const period = getPeriod(plan.servicePlan.billingFrequency);
  const messages = [`Subscribe for $${priceInDollars} each ${period}. Cancel at any time`]

  if (!billingState.isPaymentModelOpen) {
    navigate(-1)
  }

  if (!billingState.loading && !billingState.isPaymentSuccess && !subscription.isSubscriptionActive && 
    (paymentAccounts && paymentAccounts.length > 0)) {
    return (
      <div>
        <div className="container">
          <CheckoutSavedCardForm messages={messages}/>
        </div>
      </div>
    )      
  } else if (subscription.isSubscriptionActive) {
    return (
      <div><SubscriptionSetupElement/></div>
    )
  } else {
    return (
      <div className="container">
        <Alert color="primary">
          {messages}
        </Alert>
        <Checkout/>
        {billingState.loading &&
          <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
        }
      </div>
    )        
  }
}

export default SubscriptionContainer;