
import { useState, React } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap'
import { billingActions } from '../../actions'
import { selectBilling } from 'src/redux/modules/billingReducer';
import { selectPaymentAccounts } from 'src/redux/modules/memberReducer';

const CheckoutSavedCardForm = () => {
  const billing = useSelector(selectBilling);
  const paymentAccounts = useSelector(selectPaymentAccounts);
  const account = paymentAccounts[0]
  const [messages] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(billingActions.purchase(billing.planIdToPurchase, billing.domainToPurchase))
  };

  let messageBuffer = []
  let delimiter = ""
  messages.forEach(element => {
    messageBuffer.push(delimiter)
    messageBuffer.push(element)      
    delimiter = <br/>
  });

  let expiration = "Expires: "
  if (account.expirationMonth && account.expirationYear)  {
    expiration = expiration.concat(account.expirationMonth).concat("/").concat(account.expirationYear);
  }

  return (

    <form onSubmit={handleSubmit}>
    hey
      <Card>
        <CardBody>
          {messageBuffer}
        </CardBody>                    
      </Card> 
      &nbsp;
      <div>
        <CardTitle>Credit Card Type: {account.creditCardType}</CardTitle>
        <CardText>{expiration}</CardText>
        <Button color="primary">Place order</Button>&nbsp;
        <Button color="secondary" onClick={e => {navigate(-1)}}>Cancel</Button>
      </div>
    </form>
  );
}

export default CheckoutSavedCardForm;