import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonGroup, Row, Col, Card, CardBody, Spinner, Badge, Alert } from 'reactstrap';
import { virtualEmailActions } from '../../actions';
import { paths } from '../../constants'
import { Link } from 'react-router-dom';
import { selectVirtualEmailConfig } from 'src/redux/modules/virtualEmailConfigReducer';
import { selectMemberReplyActive, selectMemberAttachActive, selectMemberAutoCreateActive } from 'src/redux/modules/memberReducer';
import { selectSubscription } from 'src/redux/modules/subscriptionReducer';

const SettingsForm = () => {

  const virtualEmailConfigState = useSelector(selectVirtualEmailConfig)
  const subscriptionState = useSelector(selectSubscription)

  const isReplyActive = useSelector(selectMemberReplyActive)
  const isAttachActive = useSelector(selectMemberAttachActive)
  const isAutoCreateActive = useSelector(selectMemberAutoCreateActive)

  const dispatch = useDispatch();
  
  const currentPlan = subscriptionState.currentPlan;
  const loading = virtualEmailConfigState.loading;

  const enableReply = () => {
    dispatch(virtualEmailActions.enableEmailReply());
  }

  const disableReply = () => {
    dispatch(virtualEmailActions.disableEmailReply());
  }

  const enableAttach = () => {
    dispatch(virtualEmailActions.enableAttach());
  }

  const disableAttach = () => {
    dispatch(virtualEmailActions.disableAttach());
  }

  const enableAutoCreate = () => {
    dispatch(virtualEmailActions.enableAutoCreate());
  }

  const disableAutoCreate = () => {
    dispatch(virtualEmailActions.disableAutoCreate());
  }

  if (!currentPlan) {
    return <div><Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /></div>
  }

  return (
    <div>
      {(!currentPlan.isReplyEnabled || !currentPlan.isAttachEnabled) && 
          <Alert color="info">
            Premium features are available with paid subscriptions. See <Link to={paths.subscriptionLandingPage}>the subscription page</Link> for more details.
          </Alert>}

      <Card body>
        <CardBody >
          <Row>
            <Col sm={{ size: 'auto', offset: 1 }}>
              <Badge color="secondary" pill>Premium</Badge>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 'auto', offset: 1 }}>
              {currentPlan.isReplyEnabled && isReplyActive  &&
              <ButtonGroup>            
                <Button color="primary" size="sm" >On</Button>
                <Button outline color="secondary" size="sm" onClick={() => disableReply()}>Off</Button>
              </ButtonGroup>
              }
              {currentPlan.isReplyEnabled && !isReplyActive &&
              <ButtonGroup>            
                <Button outline color="secondary" size="sm" onClick={() => enableReply()}>On</Button>
                <Button color="primary" size="sm">Off</Button>
              </ButtonGroup>
              }
              {!currentPlan.isReplyEnabled &&
                <ButtonGroup disabled >            
                  <Button color="primary" size="sm" disabled >On</Button>
                  <Button outline color="secondary" size="sm" disabled>Off</Button>
                </ButtonGroup>
              }
            &nbsp;Enable anonymous replies
            </Col>
          </Row>
          <Row>    
            &nbsp;          
          </Row>
          <Row>
            <Col sm={{ size: 'auto', offset: 1 }}>
              <Badge color="secondary" pill>Premium</Badge>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 'auto', offset: 1 }}>
          
              {currentPlan.isAttachEnabled && isAttachActive &&
              <ButtonGroup>            
                <Button color="primary" size="sm" >On</Button>
                <Button outline color="secondary" size="sm" onClick={() => disableAttach()}>Off</Button>
              </ButtonGroup>
              }
              {currentPlan.isAttachEnabled && !isAttachActive &&
              <ButtonGroup>            
                <Button outline color="secondary" size="sm" onClick={() => enableAttach()}>On</Button>
                <Button color="primary" size="sm">Off</Button>
              </ButtonGroup>
              }
              {!currentPlan.isAttachEnabled && 
                <ButtonGroup disabled >            
                  <Button color="primary" size="sm" disabled >On</Button>
                  <Button outline color="secondary" size="sm" disabled>Off</Button>
                </ButtonGroup>
              }
              &nbsp;Include attachments
            </Col>
          </Row>
          <Row>    
            &nbsp;          
          </Row>
          <Row>
            <Col sm={{ size: 'auto', offset: 1 }}>
              <Badge color="secondary" pill>Premium</Badge>
            </Col>
          </Row>
          <Row>
            <Col sm={{ size: 'auto', offset: 1 }}>
          
              {currentPlan.isAutoCreateEnabled && isAutoCreateActive &&
              <ButtonGroup>            
                <Button color="primary" size="sm" >On</Button>
                <Button outline color="secondary" size="sm" onClick={() => disableAutoCreate()}>Off</Button>
              </ButtonGroup>
              }
              {currentPlan.isAutoCreateEnabled && !isAutoCreateActive &&
              <ButtonGroup>            
                <Button outline color="secondary" size="sm" onClick={() => enableAutoCreate()}>On</Button>
                <Button color="primary" size="sm">Off</Button>
              </ButtonGroup>
              }
              {!currentPlan.isAutoCreateEnabled && 
                <ButtonGroup disabled >            
                  <Button color="primary" size="sm" disabled >On</Button>
                  <Button outline color="secondary" size="sm" disabled>Off</Button>
                </ButtonGroup>
              }
              &nbsp;Create virtual emails automatically
            </Col>
          </Row>

          <Row>
            <Col>
              { loading && <div><Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /></div>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default SettingsForm;