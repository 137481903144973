import { userConstants } from '../constants';
import { alertActions } from './';
import { userService } from '../services'
import { deriveErrorMessage } from '../helpers/cognitoUtil'
// not quite right.
import { virtualEmailActions } from './';
import { paths } from '../constants'

export const userActions = {
  register, login, logout, verifyToken, refresh, sendEmailConfirmToken, refreshAuth, sendPasswordResetCode, 
  submitPasswordResetCode, changePassword
};

function login(user, navigate) {

  return dispatch => {
    dispatch(request());
    const email = user.email
    const password = user.password

    return userService.login(email, password).then(res => {
      if (res) {
        navigate(paths.forwarding, { replace: true });
        dispatch(success(email));
        dispatch(alertActions.clear());
        dispatch(virtualEmailActions.getSummary(false, navigate));
      } else {
        dispatch(failure("Login failed"));
        dispatch(alertActions.error("Login failed"));
      }
    }).catch(err => {
      if (err.code) {
        dispatch(failure(err.message));
        dispatch(alertActions.error(err.message));
      } else {
        dispatch(failure(err.toString()));
        dispatch(alertActions.error(err.toString()));
      }
    })
  }
  function request() { return { type: userConstants.LOGIN_REQUEST } }
  function success(email) { 
    return { type: userConstants.LOGIN_SUCCESS, email } 
  }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function refreshAuth() {
  return dispatch => {
    return userService.refreshAuth().then(res => {
      dispatch(success(res));
    }).catch(err => {
      if (err.code) {
        // in this case, don't display this message - just redirect back to login
        // log the code so we can possibly handle this more cleanly
        if (err.message !== 'Refresh Token has expired') {
          dispatch(alertActions.error(err.message));
        } else {
          console.log(err.code);
        }
        dispatch(failure(err.message));
      } else {
        dispatch(failure(err.toString()));
        dispatch(alertActions.error(err.toString()));
      }
    })
  }
  function success(email) { return { type: userConstants.LOGIN_SUCCESS, email } }
  function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(success())
    userService.logout();
    return { type: userConstants.LOGOUT };
  }
  function success() { return { type: userConstants.LOGOUT } }
}

function register(user, navigate) {

  return dispatch => {
    dispatch(request(user));

    const email = user.email
    const password = user.password

    userService.register(email, password)
      .then(res => {
        userService.login(email, password).then(res => {
          dispatch(success(user));
          userService.sendEmailConfirmToken(email);
          dispatch(alertActions.success('Registration successful. Please check your email to complete registration.'));
          navigate(paths.verifyEmail);
        }).catch(err => {
          if (err.code) {
            dispatch(failure(err.message));
            dispatch(alertActions.error(err.message));
          } else {
            dispatch(failure(err.toString()));
            dispatch(alertActions.error(err.toString()));
          }
        })
      }).catch(err => {
        const message = deriveErrorMessage(err);
        dispatch(failure(message));
        dispatch(alertActions.error(message));
      })
  }
  function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
  function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
  function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function verifyToken(username, token, navigate) {
  return dispatch => {
    dispatch(request());
    return userService.verifyToken(username, token)
      .then(res => {
        return dispatch(refresh(res))
          .then(res => {
            dispatch(success(res));
            dispatch(alertActions.success('Email confirmation complete. You can now setup email forwarding.'));
            navigate(paths.forwarding);
          })
      }).catch(err => {
        if (err.code && err.code === "CodeMismatchException") {
          dispatch(mismatch());
        } else if (err.message) {
          dispatch(failure(err.message));
        } else {
          dispatch(failure("An unexpected error occurred. Please try again"));
        }
      })
  }
  function request() { return { type: userConstants.VERIFY_TOKEN_REQUEST } }
  function success() { return { type: userConstants.VERIFY_TOKEN_SUCCESS } }
  function failure(error) { return { type: userConstants.VERIFY_TOKEN_FAILURE, error } }
  function mismatch() { return { type: userConstants.VERIFY_TOKEN_MISMATCH } }
}

function refresh() {
  return dispatch => {
    return userService.refresh().then(res => {
      dispatch(success(res))
    })
    function success(summary) { return { type: userConstants.GET_MEMBER_SUCCESS, summary } }
  }
}

function sendEmailConfirmToken(email) {
  return dispatch => {
    return userService.sendEmailConfirmToken(email).then(res => {
      dispatch(success());
      dispatch(alertActions.success("Code resent. Please check your email."));
    }).catch(err => {
      dispatch(failure(err));
      dispatch(alertActions.error("Error sending code. Please try again later."));
    })
    function success() { return { type: userConstants.RESEND_TOKEN_SUCCESS } }
    function failure(error) { return { type: userConstants.RESEND_TOKEN_FAILURE, error } }
  }
}

function sendPasswordResetCode(email) {
  return dispatch => {
    dispatch(request());
    return userService.sendPasswordResetCode(email).then(res => {
      dispatch(success(email));
      dispatch(alertActions.success("Code sent. Please check your email."));
    }).catch(err => {
      if (err.code) {
        if (err.code === "UserNotFoundException" || err.code === "InvalidParameterException") {
          dispatch(failure("User not found"));
          dispatch(alertActions.error("User not found"));
        } else {
          dispatch(failure(err.message));
          dispatch(alertActions.error(err.message));
        }
      } else {
        dispatch(failure(err.toString()));
        dispatch(alertActions.error(err.toString()));
      }
    })
    function request() { return { type: userConstants.PASSWORD_RESET_REQUEST } }
    function success(email) { return { type: userConstants.PASSWORD_RESET_SUCCESS, email } }
    function failure(error) { return { type: userConstants.PASSWORD_RESET_FAILURE, error } }
  }
}

function submitPasswordResetCode(email, code, password, navigate) {
  return dispatch => {
    dispatch(request());
    return userService.submitPasswordResetCode(email, code, password).then(res => {
      dispatch(success());
      const user = { 'email': email, 'password': password };
      dispatch(login(user));
      dispatch(alertActions.success("Password reset."));
      navigate('/account', { replace: true });
    }).catch(err => {
      if (err.code && err.code === "UserNotFoundException") {
        dispatch(failure("User not found"));
        dispatch(alertActions.error("User not found"));
      } else {
        const message = deriveErrorMessage(err);
        dispatch(failure(message));
        dispatch(alertActions.error(message));
      }
    })
    function request() { return { type: userConstants.PASSWORD_RESET_SUBMIT_REQUEST } }
    function success() { return { type: userConstants.PASSWORD_RESET_SUBMIT_SUCCESS } }
    function failure(error) { return { type: userConstants.PASSWORD_RESET_SUBMIT_FAILURE, error } }
  }
}

function changePassword(currentPassword, newPassword) {
  return dispatch => {
    dispatch(request());
    return userService.changePassword(currentPassword, newPassword).then(res => {
      dispatch(success());
      dispatch(alertActions.success("Password updated"));
    }).catch(err => {
      const message = deriveErrorMessage(err);
      dispatch(failure(message));
      dispatch(alertActions.error(message));
    })
    function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
    function success() { return { type: userConstants.CHANGE_PASSWORD_SUCCESS } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
  }
}