import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Card, CardBody } from 'reactstrap';
import { selectEmailDomainsPendingSetup } from 'src/redux/modules/memberReducer';
import { selectDomain } from 'src/redux/modules/domainReducer';

const CustomDomainSetupElement = () => {

  const emailDomainsPendingSetup = useSelector(selectEmailDomainsPendingSetup);
  const domainState = useSelector(selectDomain);

  // smells like bad state setup
  let pendingDomainName = ""
  if (emailDomainsPendingSetup && emailDomainsPendingSetup.length > 0) {
    pendingDomainName = emailDomainsPendingSetup[0].domainName
  } else {
    pendingDomainName = domainState.domainName;
  }

  return (
    <div>         
      <Card body>
        <CardBody>
          <Alert color="success">
            <h4 className="alert-heading">Your account is being setup.</h4>
            <p>
              Your payment has been received and your custom domain ({pendingDomainName}) is in the process of being registered.
            </p>
            <hr />
            <p className="mb-0">
              The registration process can sometimes take several hours. Your account will be updated once registration is complete.
            </p>
          </Alert>
        </CardBody>
      </Card>
    </div>
  );
}
export default CustomDomainSetupElement;