import { billingConstants } from '../../constants';

const initialState = {
  loading: null,
  error: null,
  domainName: null,
  isPaymentSuccess: false,
  planIdToPurchase: null,
  domainToPurchase: null,
  isCardToBeUpdated: false,
  isPaymentInfoRequired: false,
  // not state, action
  isPaymentCancelled: false,
  isPaymentModelOpen: false,
};

export function billing(state = initialState, action) {
  switch (action.type) {
    case billingConstants.PAYMENT_INFO_REQUIRED:
      return { ...state, domainName: action.domainName, loading: false, error: null, isPaymentInfoRequired: true, isPaymentCancelled: false };
    case billingConstants.PAYMENT_INFO_SAVE_ERROR:
      return { ...state, domainName: action.domainName, loading: false, error: action.error, isPaymentInfoRequired: true };
    case billingConstants.PAYMENT_INFO_SAVE_SUCCESS:
      return { ...state, domainName: action.domainName, isPaymentSuccess:true, loading: false, error: null,  isPaymentInfoRequired: false, isCardToBeUpdated: false, isPaymentModelOpen: false};
    case billingConstants.UPDATE_CART:
      // reset isPaymentSuccess state on each cart update, as it's later used as a condition for rendering
      return { ...state, domainToPurchase: action.domainToPurchase, planIdToPurchase:action.planIdToPurchase, isPaymentSuccess:false, isPaymentCancelled: false};  
    case billingConstants.UPDATE_CARD:
      return { ...state, isCardToBeUpdated: true, isPaymentCancelled: false, isPaymentModelOpen: true };    
    case billingConstants.CANCEL_UPDATE_CARD:
      return { ...state, isCardToBeUpdated: false, isPaymentCancelled: true };
    case billingConstants.PURCHASE_REQUEST:
      return { ...state, loading: true };
    case billingConstants.PURCHASE_SUCCESS:
      return { ...state, planIdToPurchase: null, isCardToBeUpdated: false, loading: false, error: null, isPaymentSuccess: true }
    case billingConstants.PURCHASE_ERROR:
      return { ...state, error: action.error, loading: false };  
    case billingConstants.PAYMENT_INFO_SAVE_REQUEST:
      return { ...state, loading: true }
    case billingConstants.REMOVE_CARD_SUCCESS: 
      return { ...state, isPaymentInfoRequired: true}
    case billingConstants.OPEN_PAYMENT_MODAL: 
      return { ...state, isPaymentModelOpen: true}
    case billingConstants.CLOSE_PAYMENT_MODAL: 
      return { ...state, isPaymentModelOpen: false}
    default:
      return state
  }
}

export const selectBilling = (state) => state.billing;