import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardTitle, CardText, Spinner } from 'reactstrap';
import { billingActions } from '../../actions';
import { selectPaymentAccounts } from 'src/redux/modules/memberReducer';
import { selectBilling } from 'src/redux/modules/billingReducer';

const ManagePaymentElement = () => {

  const dispatch = useDispatch();
  const billingState = useSelector(selectBilling);
  const paymentAccounts = useSelector(selectPaymentAccounts);
  const account = paymentAccounts[0]

  const remove = (paymentAccountId) => {
    dispatch(billingActions.removeCard(paymentAccountId));
  }

  const update = (paymentAccountId) => {
    dispatch(billingActions.updateCard(paymentAccountId));
  }

  let expiration = "Expires: "
  if (account.expirationMonth && account.expirationYear)  {
    expiration = expiration.concat(account.expirationMonth).concat("/").concat(account.expirationYear);
  }

  return (
    <div>
      <Card body>
        <CardBody >
          <CardTitle>Credit Card Type: {account.creditCardType}</CardTitle>
          <CardText>{expiration}</CardText>
          <Button color="secondary" onClick={() => update(account.id)}>Update</Button>&nbsp;&nbsp;
          <Button color="danger" onClick={() => remove(account.id)}>Remove</Button>
        </CardBody>
      </Card>
      {billingState.loading &&
        <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
      }
    </div>
  );
}

export default ManagePaymentElement;