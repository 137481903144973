import { userConstants } from '../../constants';

const initialState = {
  loading: false,
  error: null,
};

export function changePassword(state = initialState, action) {
  switch (action.type) {
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: null };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state
  }
}

export const selectChangePassword = (state) => state.changePassword;
