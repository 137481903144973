

export function getParamValue(url, paramName) {

  // const urlParts = window.location.href.split("?");
  const urlParts = url.split("?");
  
  if (urlParts.length === 1) {
    return null;
  }

  const queryParams = urlParts[1].split("&");

  for (let pair of queryParams) {    
    const keyValue = pair.split("=");
    if (keyValue.length === 2) {
      if (keyValue[0] === paramName) {
        return keyValue[1]
      }
    }
  }

  return null;
}
