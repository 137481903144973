import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Input, 
  Form, 
  Card, 
  CardBody, 
  FormFeedback, 
  Button, 
  Spinner, 
  InputGroup,
  InputGroupText,
  Tooltip
} from 'reactstrap';
import { virtualEmailActions } from '../../actions'
import MediaQuery from 'react-responsive'
import { selectVirtualEmails } from '../../redux/modules/virtualEmailReducer';
import { selectEmailDomains, selectPublicToken } from '../../redux/modules/memberReducer';
import { selectSubscription } from '../../redux/modules/subscriptionReducer';

const AddVirtualEmailForm = () => {
  const [virtualEmail, setVirtualEmail] = useState({
    name: '',
    domainId: null
  });
  const domains = useSelector(selectEmailDomains);
  const subscription = useSelector(selectSubscription);
  const [submitted, setSubmitted] = useState(false);
  const publicToken = useSelector(selectPublicToken);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const virtualEmails = useSelector(selectVirtualEmails);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVirtualEmail(virtualEmail => ({
      ...virtualEmail,
      [name]: value
    }))
  }

  const toggle = () => {
    setTooltipOpen(!isTooltipOpen);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // if the domain drop-down has not been interacted; has only one item, domainId will be null
    // default to the first domain's id
    if (virtualEmail !== undefined && virtualEmail.name && !virtualEmail.domainId) {
      virtualEmail.domainId = domains[0].id;
    }

    if (virtualEmail && virtualEmail.name && virtualEmail.domainId) {
      dispatch(virtualEmailActions.addVirtualEmail(virtualEmail.name, virtualEmail.domainId));
      setSubmitted(false);
      // todo: clear the input form when adding is successful
    }
  }

  let submissionError = ""
  if (submitted && (virtualEmail === null || !virtualEmail.name)) {
    submissionError = "Please enter a name"
  } else {
    if (virtualEmails.addError !== null && virtualEmails.addError !== undefined) {
      submissionError = virtualEmails.addError
    }
  }

  let tokenPart;

  if (subscription.currentPlan &&   subscription.currentPlan.subscriptionType === 'CUSTOM_DOMAIN' ) {
    tokenPart = "@"
  } else {
    tokenPart = "-" + publicToken + "@"
  }      
  const tokenHelperText = 'This token allows you pick any name. Upgrade to a custom domain to remove.'

  const emailNameElement = 
  <React.Fragment>
    <Input invalid={submissionError!==""} addon type="name" name="name" id="name" placeholder="Name" onChange={handleChange} />           
    <FormFeedback>{submissionError}</FormFeedback>
    <InputGroupText><span href="#" id="TokenTooltip">{tokenPart}</span></InputGroupText>
    {tokenPart !== "@" &&
      <Tooltip placement="right" isOpen={isTooltipOpen} target="TokenTooltip" toggle={toggle}>
        {tokenHelperText}</Tooltip>
    }
  </React.Fragment>

  return (
    <div>
      { (domains === undefined || domains.length === 0) &&
        <div><Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /></div>
      }
      { (domains !== undefined && domains.length > 0) &&
          <div>
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit} type="post">
                  <MediaQuery maxWidth={480}>
                    <InputGroup size="sm">
                      {emailNameElement}
                    </InputGroup>
                    <Input type="select" name="domainId" id="domain" onChange={handleChange}>
                      {domains.map(domain =>
                        <option key={domain.id} value={domain.id}>{domain.domainName}</option>
                      )}
                    </Input>
                  </MediaQuery>
                  <MediaQuery minWidth={481}>
                    <InputGroup size="sm">
                      {emailNameElement}
                      <Input type="select" name="domainId" id="domain" onChange={handleChange}>
                        {domains.map(domain =>
                          <option key={domain.id} value={domain.id}>{domain.domainName}</option>
                        )}
                      </Input>
                    </InputGroup>
                  </MediaQuery>
                  <br/>
                  <Button color="primary" size="sm">Add New Forwarding Address</Button>
                  {virtualEmails.adding &&
                <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
                  }
                </Form>
              </CardBody>
            </Card>
          </div>
      }
    </div>
  );
}

export default AddVirtualEmailForm;