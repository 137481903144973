import React from 'react';
import {Button } from 'reactstrap';
import config from '../../config'
import { NavLink as RRNavLink } from 'react-router-dom';

var style = {
  backgroundColor: "#F8F8F8",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "3px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "40px",
  width: "100%",
}

var phantom = {
  display: 'block',
  padding: '3px',
  height: '40px',
  width: '100%',
}

function Footer({ children }) {

  // const urlWithQuery = window.location.pathname + window.location.search
  const urlWithQuery = ""
  let pixelUrl = config.pixelBaseUrl + '&url=' + encodeURIComponent(urlWithQuery)

  const utmElements = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

  utmElements.forEach(element => {
    const value = localStorage.getItem(element);
    if (value !== null) {
      pixelUrl = pixelUrl + "&" + element + "=" + value;
    }
  })

  return (
    <div>
      <div style={phantom} />
      <div style={style}>
        {children}
        <div style={{ textAlign: 'center'}} >
          <a style={{color: "#808080"}} className="btn-sm" color="link" target="_blank" rel="noopener noreferrer" href="https://tethyst.com/">Built by Tethyst</a>
          <Button style={{color: "#808080"}} size="sm" color="link" tag={RRNavLink} to="/privacy-policy">Privacy</Button>
          <Button style={{color: "#808080"}} size="sm" color="link" tag={RRNavLink} to="/terms-of-service">Terms</Button>
        </div>
      </div>
      <img src={pixelUrl} alt="ping"/>
    </div>
  )
}

export default Footer