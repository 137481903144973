import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { appReducer } from './modules/appReducer';
import ReduxThunk from 'redux-thunk';
import { registration } from './modules/registerReducer';
import { alert } from './modules/alertReducer';
import { authentication } from './modules/authReducer';
import { member } from './modules/memberReducer';
import { plan } from './modules/planReducer';
import { subscription } from './modules/subscriptionReducer';
import { virtualEmails } from './modules/virtualEmailReducer';
import { emailVerify } from './modules/emailVerifyReducer';
import { forgotPassword } from './modules/forgotPasswordReducer';
import { changePassword } from './modules/changePasswordReducer';
import { virtualEmailConfig } from './modules/virtualEmailConfigReducer';
import { domain } from './modules/domainReducer';
import { billing } from './modules/billingReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(ReduxThunk,))(createStore);

const rootReducer = combineReducers({
  app: appReducer,
  registration,
  alert,
  authentication,
  member,
  plan,
  subscription,
  emailVerify,
  virtualEmails,
  forgotPassword,
  changePassword,
  virtualEmailConfig,
  domain,
  billing
});

function configureStore(initialState = {}) {
  return createStoreWithMiddleware(rootReducer, initialState);
};

const store = configureStore();

export default store;