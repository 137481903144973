import { signIn, signOut, signUp, getCurrentUser, updatePassword,confirmUserAttribute, 
  resetPassword, confirmResetPassword, fetchAuthSession, sendUserAttributeVerificationCode } from 'aws-amplify/auth';

import config from '../config'
import { LimitError } from '../errors/errors'

export const userService = {
  login, logout, register, getVirtualEmails, addVirtualEmail, removeVirtualEmail, callGetSummary,
  verifyToken, refresh, sendEmailConfirmToken, refreshAuth, sendPasswordResetCode, submitPasswordResetCode,
  isLoggedIn, activateVirtualEmail, deactivateVirtualEmail, changePassword, enableEmailReply, disableEmailReply,
  registerDomain, billingSave, billingPurchase, downgradeService, removeCard, enableAttach, disableAttach, callService
};

async function login(email, password) {
  const username = email

  try {
    const { isSignedIn } = await signIn({ username, password });

    if (isSignedIn) {
      setLoggedIn();
    }
    return isSignedIn;
  } catch (error) {
    return false;
  }
}

async function refreshAuth() {
  try {
    const result = await fetchAuthSession()
    const loginId = result?.tokens?.signInDetails?.loginId;
    setLoggedIn();
    return loginId;
  } catch(error) {
    setLoggedOut();
    throw (error);
  }
}

function logout() {
  setLoggedOut();
  signOut();
}

function changePassword(oldPassword, newPassword) {
  return getCurrentUser().then(function (user) {
    return new Promise((resolve, reject) => {
      updatePassword({oldPassword, newPassword}).then(() => {
        resolve();
      }).catch((e) => {
        reject(e);
      });
    });
  });
}

function setLoggedIn() {
  localStorage.setItem('loggedIn', true);
}

function isLoggedIn() {
  return localStorage.getItem('loggedIn') === "true";
}

// ugly workarounds due to inability to access state in private route functions
function setLoggedOut() {
  localStorage.setItem('loggedIn', false);
}

function register(email, password) {
  const username = email

  const attributes = { 'email': email, 'custom:referring_url': document.referrer }
  const utmElements = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

  utmElements.forEach(element => {
    const value = localStorage.getItem(element);
    if (value !== null) {
      attributes['custom:' + element] = value;
    }
  })

  return signUp({
    username,
    password,
    attributes: attributes
  });
}

async function verifyToken(username, confirmationCode) {
  const userAttributeKey = "email"
  return confirmUserAttribute({userAttributeKey, confirmationCode});
}

async function sendEmailConfirmToken(username) {
  const userAttributeKey = "email"
  return sendUserAttributeVerificationCode({userAttributeKey});
}

function sendPasswordResetCode(username) {
  return resetPassword({username});
}

function submitPasswordResetCode(username, confirmationCode, newPassword) {
  return confirmResetPassword({username, confirmationCode, newPassword});
}

function refresh() {
  return callService('GET', '/auth/refresh', null);
}

function getVirtualEmails() {
  return callService('GET', '/virtualEmail/list', null);
}

function addVirtualEmail(username, domainId) {
  const opts = { 'username': username, 'domainId': domainId };
  return callService('POST', '/virtualEmail/add', opts)
}

function removeVirtualEmail(virtualEmailId) {
  const opts = { 'virtualEmailId': virtualEmailId };
  return callService('POST', '/virtualEmail/remove', opts)
}

function callGetSummary() {
  return callService('GET', '/member/show', null)
}

function activateVirtualEmail(virtualEmailId) {
  const opts = { 'virtualEmailId': virtualEmailId };
  return callService('POST', '/virtualEmail/activate', opts)
}

function deactivateVirtualEmail(virtualEmailId) {
  const opts = { 'virtualEmailId': virtualEmailId };
  return callService('POST', '/virtualEmail/deactivate', opts)
}

function enableEmailReply() {
  const opts = {};
  return callService('POST', '/virtualEmail/enableReply', opts)
}

function disableEmailReply() {
  const opts = {};
  return callService('POST', '/virtualEmail/disableReply', opts)
}

function enableAttach() {
  const opts = {};
  return callService('POST', '/virtualEmail/enableAttachment', opts)
}

function disableAttach() {
  const opts = {};
  return callService('POST', '/virtualEmail/disableAttachment', opts)
}

function registerDomain(domainName) {
  const opts = { 'domainName': domainName};
  return callService('POST', '/domain/register', opts)
}

function billingPurchase(planId, domainName) {
  const opts = { 
    'planId': planId,
    'domainName': domainName
  };
  return callService('POST', '/billing/purchase', opts)
}

function billingSave(token, billingAddressZip, billingAddressCountry, creditCardBrand, creditCardExpMonth, creditCardExpYear,
  creditCardLastFour) {
  const opts = { 
    'token': token,
    'billingAddressZip': billingAddressZip,
    'billingAddressCountry': billingAddressCountry,
    'creditCardBrand': creditCardBrand, 
    'creditCardExpMonth': creditCardExpMonth,
    'creditCardExpYear': creditCardExpYear,
    'creditCardLastFour': creditCardLastFour
  };
  return callService('POST', '/billing/save', opts)
}

function downgradeService(isCancel, targetPlanId) {
  let opts = {}
  if (isCancel) {
    opts = {
      'isCancel': true
    };
  } else {
    opts = {
      'planId': targetPlanId
    };
  }
  return callService('POST', '/billing/downgrade', opts)
}

function removeCard(paymentAccountId) {
  const opts = {
    'id': paymentAccountId
  };
  return callService('POST', '/billing/remove', opts)
}

function callService(method, endpoint, opts) {
  // console.log("calling service " + endpoint + " with method " + method + " and opts " + JSON.stringify(opts));
  let payload
  if (opts !== null) {
    payload = JSON.stringify(opts);
  } else {
    payload = null;
  }
  return attemptToGetToken().then(function (token) {
    return fetchWithTimeout(config.baseApiUrl + endpoint,
      {
        method: method,
        body: payload,
        headers: { "Authorization": token }
      })
      .then(
        response => {
          if (response.ok) {
            return response.json().then(json => {
              // the status was ok and there is a json body
              return json;
            }).catch(err => {
              // the status was ok but there is no json body
              return Promise.resolve({ response: response });
            });
          } else {
            return response.json().catch(err => {
              // the status was not ok and there is no json body
              throw new Error(response.statusText);
            }).then(json => {
              // the status was not ok but there is a json body
              
              // need a more generic way to handle these errors
              if (json.errorCode === "reached_email_limit") {
                throw new LimitError(json.message);
              } else {
                throw new Error(json.message);
              }              
            });
          }
        }
      )
  })
}

// default timeout of 29s slightly under api gateway's 30s
function fetchWithTimeout(url, options, timeout = 29000) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('timeout')), timeout)
    )
  ]);
}

function attemptToGetToken() {
  return new Promise((resolve, reject) => {
    fetchAuthSession().then((tokens) => {
      const idToken = tokens?.tokens?.idToken?.toString();
      resolve(idToken);
    }).catch((error) => {
      reject(error.toString());
    });
  })
}
