import { userConstants } from '../../constants';

const initialState = {
  availablePlans: {},
  servicePlan: null,
  customDomainPlan: null,
};

export function plan(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_MEMBER_SUCCESS:
      let servicePlan = null;
      let customDomainPlan = null;
      for (const plan of action.summary.availablePlans) {
        if (plan.subscriptionType === 'SERVICE') {
          servicePlan = plan;
        } else if (plan.subscriptionType === 'CUSTOM_DOMAIN') {
          customDomainPlan = plan;
        }
      }

      const planMap = {};
      action.summary.availablePlans.forEach(plan=> {
        planMap[plan.id] = plan;
      });

      return { ...state, loading: false, error: null, 
        availablePlans: planMap,
        servicePlan: servicePlan,
        customDomainPlan: customDomainPlan,
      };
    default:
      return state
  }
}

export const selectPlan = (state) => state.plan;