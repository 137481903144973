import React from 'react';
import { Row, Col, Badge, Container } from 'reactstrap';
import { ServicePlanSubscriptionCard , CustomDomainSubscriptionCard } from '../../components/Subscription'
import { useSelector } from 'react-redux';
import { selectSubscription } from 'src/redux/modules/subscriptionReducer';

const SubscriptionOptionsContainer = () => {

  const subscription = useSelector(selectSubscription)

  return (
    <Container>
      <Row>
        <React.Fragment key="1">
          <Col>
            {subscription.isSubscriptionActive && !subscription.isCustomDomainSubscription && <Badge color="info">Your current plan </Badge>}
            {subscription.isSubscriptionActive && !subscription.isCustomDomainSubscription && <br/>}
            <ServicePlanSubscriptionCard isSubscribeLink={!subscription.isSubscriptionActive}/>
          </Col>
        </React.Fragment>
        <React.Fragment key="2">
          <Col>
            {subscription.isCustomDomainSubscription && <Badge color="info">Your current plan </Badge>}
            {subscription.isCustomDomainSubscription && <br/>}
            <CustomDomainSubscriptionCard isSubscribeLink={!subscription.isCustomDomainSubscription}/>
          </Col>
        </React.Fragment>
      </Row>
    </Container>
  )
}

export default SubscriptionOptionsContainer;