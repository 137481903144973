import { userConstants } from '../../constants';

const initialState = {
  loading: false,
  error: null,
  mismatch: false,
  verified: false
};

export function emailVerify(state = initialState, action) {
  switch (action.type) {
    case userConstants.VERIFY_TOKEN_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.VERIFY_TOKEN_SUCCESS:
      return { ...state, loading: false, error: null, mismatch:false, verified: true };
    case userConstants.VERIFY_TOKEN_FAILURE:
      return { ...state, loading: false, error: action.error };
    case userConstants.VERIFY_TOKEN_MISMATCH:
      return { ...state, loading: false, mismatch: true};
    default:
      return state
  }
}

export const selectEmailVerify = (state) => state.emailVerify;