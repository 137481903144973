import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { App } from './containers/App/App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from './redux/store'
import { Provider as ReduxProvider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import config from './config';

Amplify.configure({
  Auth: {
    Cognito: {
      region: config.awsRegion,
      userPoolId: config.cognito.userPoolId,
      userPoolClientId: config.cognito.userPoolWebClientId,
    },
  }
});

const root = createRoot(document.getElementById("root"));
root.render(
  <Router>
    <ReduxProvider store={store}>
      <App /> 
    </ReduxProvider>
  </Router>
);

registerServiceWorker();