import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Input, Form, FormGroup, Label, FormFeedback, Button, Container, Col, Card, CardTitle, CardBody, Spinner } from 'reactstrap';
import { userActions } from '../../actions'
import { selectForgotPassword } from '../../redux/modules/forgotPasswordReducer';

const ForgotPasswordForm = () => {

  const [form, setForm ] = useState({
    email: '',
    code: '',
    password: '',
    submitted: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotPasswordState = useSelector(selectForgotPassword);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm(form => ({
      ...form,
      [name]: value
    }));
  }

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    setForm(form => ({
      ...form, submitted: true }));

    if (form.email) {
      setForm(form => ({
        ...form, submitted: false }));  
      dispatch(userActions.sendPasswordResetCode(form.email));

      // reset the form as a workaround; email address is set as the code value with the current form
      event.target.reset();
    }    
  }

  const handleCodeSubmit = (event) => {
    event.preventDefault();
    setForm(form => ({
      ...form, submitted: true }));

    if (form.email && form.code && form.password) {
      dispatch(userActions.submitPasswordResetCode(form.email, form.code, form.password, navigate));
    }
  }

  const emptyCode = form.submitted && !form.code;
  const emptyPassword = form.submitted && !form.password;
  const loading = forgotPasswordState.loading

  const renderCodeElement = 
  <React.Fragment>
    <div>
      <Container>
        <Col sm={{ size: 'auto', offset: 1 }}>
          <Card body>
            <CardTitle>
              Please enter the code from the email.
            </CardTitle>
            <CardBody>
              <Form onSubmit={handleCodeSubmit} type="post">
                <FormGroup row> 
                  <Label for="code">Code</Label>
                  <Input invalid={emptyCode} name="code" id="code" onChange={handleChange}  />
                  <FormFeedback invalid="{emptyCode}">Code required.</FormFeedback>
                </FormGroup>
                <FormGroup row>                
                  <Label for="password">New Password</Label>
                  <Input type="password" invalid={emptyPassword} name="password" id="password" placeholder="Password" onChange={handleChange} />
                  <FormFeedback invalid="{emptyPassword}">Password required.</FormFeedback>
                </FormGroup>
                <Button color="primary">Reset password</Button>
                {loading &&
                  <div><Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /></div>
                }
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  </React.Fragment>

  const emptySubmission = form.submitted && !form.email;

  const renderEmailElement =
  <React.Fragment>
    <div>
      <Container>
        <Col sm={{ size: 'auto', offset: 1 }}>
          <Card body>
            <CardTitle>
              Please enter your email address to request a new password.
            </CardTitle>
            <CardBody>
              <Form onSubmit={handleEmailSubmit} type="post">
                <FormGroup row>
                  <Label for="email">Email</Label>
                  <Input invalid={emptySubmission} name="email" id="email" onChange={handleChange} />
                  <FormFeedback invalid="{emptySubmission}">Please enter your email address.</FormFeedback>
                </FormGroup>
                <FormGroup row>
                  <Button color="primary">Request password reset code</Button>
                  {loading &&
                    <div><Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /></div>
                  }
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  </React.Fragment>

  const emailSubmitted = forgotPasswordState.emailSubmitted;

  if (emailSubmitted) {
    return renderCodeElement;
  } else {
    return renderEmailElement;
  }
}

// function mapStateToProps(state) {
//   return {
//     loading: state.forgotPassword.loading,
//     error: state.forgotPassword.error,
//     emailSubmitted: state.forgotPassword.emailSubmitted,
//     email: state.forgotPassword.email,
//     code: state.forgotPassword.code
//   };
// }

export default ForgotPasswordForm ;