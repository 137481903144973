import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Input, Form, FormGroup, FormFeedback, Button, Label, Card, CardTitle, CardBody, Spinner} from 'reactstrap';
import { userActions } from '../../actions'
import { Link } from 'react-router-dom'
import { NavLink as RRNavLink } from 'react-router-dom';
import { selectLoggingIn } from '../../redux/modules/authReducer';

const LoginForm = () => {
  const [user, setUser] = useState({
    email: '',
    password: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const loggingIn = useSelector(selectLoggingIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(userActions.logout());
  }, [dispatch]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser(user => ({
      ...user,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (user.email && user.password) {
      dispatch(userActions.login(user, navigate));
    }
  };

  const invalidEmail = submitted && !user.email;
  const invalidPassword = submitted && !user.password;

  return (
    <div>         
      <Card body>
        <CardTitle>
          Welcome back. Please login.
        </CardTitle>
        <CardBody>
          <Form onSubmit={handleSubmit} type="post">
            <FormGroup row>
              <Label for="email">Email</Label>
              <Input invalid={invalidEmail} type="email" name="email" id="email" onChange={handleChange} />
              <FormFeedback invalid="{invalidEmail}">Email address is required</FormFeedback>
            </FormGroup>
            <FormGroup row>
              <Label for="password">Password</Label>
              <Input invalid={invalidPassword} type="password" name="password" id="password" onChange={handleChange} />
              <FormFeedback invalid="{invalidPassword}">Password is required</FormFeedback>
            </FormGroup>
            <FormGroup row>
              <Button color="primary">Login</Button>&nbsp;
              <Button color="link" tag={RRNavLink} to="/forgotPassword"><span className="sm">Forgot Passsword?</span></Button>
              {loggingIn &&
                <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
              }          
            </FormGroup>
            <FormGroup row>
              <span style={{fontSize:14 +'px'}}>No account?&nbsp;<Link to="/register">Register here.</Link></span>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </div>  );
};

export default LoginForm;