import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import guardIdentity from '../../guard_identity.svg';
import breachScan from '../../breach_scan.svg';
import addVirtualEmail from '../../add_virtual_email.svg';
import MediaQuery from 'react-responsive'
import { paths } from '../../constants'
import { NavLink as RRNavLink } from 'react-router-dom';

const Home = () => {
  
  const items = [
    {
      id: 1,
      altText: 'Mask your online presence by hiding your primary email address',
      caption: 'Mask your online presence by hiding your primary email address',
      image: guardIdentity,
    },
    {
      id: 2,
      altText: 'Create disposable email addresses for signing up on other websites',
      caption: 'Create disposable email addresses for signing up on other websites',
      image: addVirtualEmail,
    },
    {
      id:3,
      altText: 'Automated scanning of public breach databases',
      caption: 'Automated scanning of public breach databases *',
      image: breachScan
    }
  ]

  const displayUnits = items.map((item) => {
    return (
      <React.Fragment key={item.id}>
        <Row>
          <Col xs="3" sm="3" md="2" lg={{size:2,offset:1}} xl={{size:1,offset:1}}>
            <img width="100%" src={item.image} alt={item.altText}/>
          </Col>
          <Col xs="9" sm="9" md="10" lg="8" xl="8" style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <MediaQuery maxWidth={700}>
              {item.caption}
            </MediaQuery>
            <MediaQuery minWidth={701}>
              <h5>
                {item.caption}
              </h5>
            </MediaQuery>
          </Col>
        </Row>
        <br/>
      </React.Fragment>
    );
  });

  return (
    <div>  
      <Container>
        <div className="rounded px-3 px-sm-4 py-3 py-sm-5" style={{backgroundColor: "#e9ecef"}}>
          <h1 className="display-5">Protect your online self</h1>
          <p className="lead">ID Unseen provides tools to help improve your security while online.</p>
          <p className="lead">
            <Button color="primary" tag={RRNavLink} to={paths.register}>Register for Free</Button>
          </p>
        </div>
        {displayUnits}
      </Container>      
    </div>
  );
}

export default Home