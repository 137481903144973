import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Card, CardBody, Button } from 'reactstrap'
import { billingActions, alertActions } from '../../actions'
import { getParamValue } from '../../helpers/urlUtil'
import { selectBilling } from 'src/redux/modules/billingReducer';

const CheckoutForm = () => {  
  const stripe = useStripe();
  const elements = useElements();
  const billing = useSelector(selectBilling);
  const dispatch = useDispatch();

  const handleCancel = async (event) => {
    event.preventDefault();
    dispatch(billingActions.closePaymentModal())
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const cardElement = elements.getElement('card');

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    }).then(({paymentMethod}) => {
      var postal_code
      if (paymentMethod && paymentMethod.billing_details && paymentMethod.billing_details.address) {
        postal_code = paymentMethod.billing_details.address.postal_code;
      }
      if (paymentMethod) {
        dispatch(billingActions.saveCreditCard(
          paymentMethod.id, 
          postal_code,
          paymentMethod.country,
          paymentMethod.brand,
          paymentMethod.exp_month,
          paymentMethod.exp_year,
          paymentMethod.last4,
          billing.planIdToPurchase,
          billing.domainToPurchase))
      } else {
        console.log("no payment method");
        dispatch(alertActions.error("Unable to process card."))
      }
    }, reason => {
      console.log(reason);
    }).catch(err => {
      dispatch(alertActions.error("Unable to process card."))
      console.log(err);
    });
  };

  let buttonText = ""

  if (billing.planIdToPurchase) {
    buttonText = "Place Order";
  } else {
    const planId = getParamValue(window.location.href, "planId");
    if (planId) { 
      dispatch(billingActions.updateCart(planId, null));
    }
    buttonText = "Save Card";
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardBody>
          <CardElement style={{base: {fontSize: '18px'}}} />
        </CardBody>          
      </Card>        
      &nbsp;
      <div>
        <Button color="primary">{buttonText}</Button>&nbsp;
        <Button color="secondary" onClick={ handleCancel }>Cancel</Button>
      </div>
    </form>
  )
}

export default CheckoutForm;
