import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Input, Form, FormGroup, Label, FormFeedback, Button, Card, CardTitle, CardBody, Spinner } from 'reactstrap';
import { userActions } from '../../actions'
import { Link } from 'react-router-dom'
import MediaQuery from 'react-responsive'
import { selectRegistration } from '../../redux/modules/registerReducer';

const RegisterForm = () =>  {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [user, setUser] = useState({
    email: '',
    password: '',
    isOptedIn: true
  });
  const registrationState = useSelector(selectRegistration);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "isOptedIn") {
      user.isOptedIn = event.target.checked
      return
    }

    setUser({
      ...user,
      [name]: value
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (user.email && user.password && user.isOptedIn) {
      dispatch(userActions.register(user, navigate));
    }
  }

  const registering = registrationState.registering
  const invalidEmail = submitted && !user.email;
  const invalidPassword = submitted && !user.password;
  const invalidOptIn = submitted && !user.isOptedIn;

  return (
    <div>
      <Card body>
        <CardTitle>
            Register to improve your online security.
        </CardTitle>
        <CardBody>                
          <Form onSubmit={handleSubmit} type="post">
            <FormGroup row>
              <MediaQuery maxWidth={480}>
                <Input invalid={invalidEmail} type="email" name="email" id="email" placeholder="Email" onChange={handleChange} />
                <FormFeedback invalid="{invalidEmail}">Email address is required</FormFeedback>                  
              </MediaQuery>
              <MediaQuery minWidth={481}>
                <Label for="email">Email</Label>
                <Input invalid={invalidEmail} type="email" name="email" id="email" onChange={handleChange} />
                <FormFeedback invalid="{invalidEmail}">Email address is required</FormFeedback>
              </MediaQuery>
            </FormGroup>
            <FormGroup row>
              <MediaQuery maxWidth={480}>
                <Input invalid={invalidPassword} type="password" name="password" id="password" placeholder="Password" onChange={handleChange} />
                <FormFeedback invalid="{invalidPassword}">Password is required</FormFeedback>
              </MediaQuery>
              <MediaQuery minWidth={481}>
                <Label for="password">Password</Label>
                <Input invalid={invalidPassword} type="password" name="password" id="password" onChange={handleChange} />
                <FormFeedback invalid="{invalidPassword}">Password is required</FormFeedback>
              </MediaQuery>
            </FormGroup>
            <FormGroup row>
              <Button color="primary">Register for free</Button>
              {registering && 
                  <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
              }
            </FormGroup>
            <FormGroup check>
              <Label className="xsm" check>
                <Input className="active" defaultChecked="true" invalid={invalidOptIn} type="checkbox" name="isOptedIn" id="isOptedIn" onChange={handleChange}/>{' '}
                  By registering you agree to the <Link to="/privacy-policy">Privacy Policy</Link>
                  &nbsp;and&nbsp;
                <Link to="/terms-of-service">Terms of Service</Link>
                <FormFeedback invalid="{invalidOptIn}">Please accept.</FormFeedback>
              </Label>
            </FormGroup>
            <br/>
            <FormGroup row>
              <span style={{fontSize:14 +'px'}}>Already Registered?&nbsp;<Link to="/login">Login here</Link></span>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default RegisterForm;