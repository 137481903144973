import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { paths } from '../../constants'
import { billingActions } from '../../actions'
import { selectPlan } from 'src/redux/modules/planReducer';

const ServicePlanSubscriptionCard = (isSubscribeLink) => {

  const dispatch = useDispatch();
  const planState = useSelector(selectPlan);
  const servicePlan = planState.servicePlan;

  const handleSubscribeClick = () =>{
    dispatch(billingActions.updateCart(servicePlan.id, null));
    dispatch(billingActions.openPaymentModal());
  }
  
  if (!servicePlan) {
    return <div></div>
  }

  const priceInDollars = servicePlan.priceInCents/100;
  const linkText = `Sign up for $${priceInDollars} ${servicePlan.billingFrequency.toLowerCase()}`;

  const subscribeUrl = paths.serviceSubscribe + "?planId=" + servicePlan.id;

  return (
    <div>         
      <Card> 
        <CardBody>            
          <h4>Virtually Unlimited Virtual Emails</h4>
          <br/>
          {isSubscribeLink.isSubscribeLink && <Button block onClick={e => handleSubscribeClick()} color="primary" tag={RRNavLink} to={subscribeUrl}>{linkText}</Button>}
          {isSubscribeLink.isSubscribeLink && <br/>}
          <ListGroup flush>
            <ListGroupItem>&#10004;&nbsp;Up to {servicePlan.maxVirtualEmails.toLocaleString()} virtual emails</ListGroupItem>
            <ListGroupItem>&#10004;&nbsp;Create virtual emails automatically</ListGroupItem>
            <ListGroupItem>&#10004;&nbsp;Attachment forwarding</ListGroupItem>
            <ListGroupItem>&#10004;&nbsp;Public breach database scanning</ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    </div>
  );
}

export { ServicePlanSubscriptionCard };