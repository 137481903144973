import React from 'react';
import { Row } from 'reactstrap';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import AccountSettingsNav  from '../../components/AccountSettingsNav/AccountSettingsNav'

const ChangePassword = () => {

  return (
    <div className="container">
      <Row>
        <AccountSettingsNav/>
      </Row>
      <br/>
      <Row>
        <ChangePasswordForm/>
      </Row>
    </div>
  );
}

export default ChangePassword;