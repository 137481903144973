import { userConstants, billingConstants } from '../../constants';

const initialState = {
  isVerified: false,
  loading: false,
  isMemberLoaded: false,
  error: null,
  publicToken: null,
  emailDomains: [],
  isReplyActive: false,
  isAttachActive: false,
  isAutoCreateActive: false,
  emailDomainsPendingSetup: [],
  memberEmailDomains: [],
  paymentAccounts: [],
};

export function member(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_MEMBER_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.GET_MEMBER_SUCCESS:
      const memberEmailDomains = [];
      for (const emailDomain of action.summary.emailDomains) {
        if (emailDomain.isMember) {        
          memberEmailDomains.push(emailDomain);
        }
      }
      return { ...state, loading: false, error: null, 
        isVerified: action.summary.isVerified, 
        publicToken: action.summary.publicToken, 
        emailDomains: action.summary.emailDomains,
        emailDomainsPendingSetup: action.summary.emailDomainsPendingSetup,
        memberEmailDomains: memberEmailDomains,
        paymentAccounts: action.summary.paymentAccounts,
        isReplyActive: action.summary.isReplyActive,
        isAttachActive: action.summary.isAttachActive,
        isAutoCreateActive: action.summary.isAutoEmailCreate,
        isMemberLoaded: true,
      };
    case userConstants.ENABLE_REPLY_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null,
        isReplyActive: action.virtualEmailConfig.isReplyActive };
    case userConstants.DISABLE_REPLY_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null,
        isReplyActive: action.virtualEmailConfig.isReplyActive };    
    case userConstants.ENABLE_ATTACH_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null,
        isAttachActive: action.virtualEmailConfig.isAttachActive };
    case userConstants.DISABLE_ATTACH_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null,
        isAttachActive: action.virtualEmailConfig.isAttachActive };  
    case userConstants.ENABLE_AUTO_CREATE_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null,
        isAutoCreateActive: action.virtualEmailConfig.isAutoCreateActive };
    case userConstants.DISABLE_AUTO_CREATE_SUCCESS:
      return { ...state, virtualEmailConfig: action.virtualEmailConfig, loading: false, error: null,
        isAutoCreateActive: action.virtualEmailConfig.isAutoCreateActive };      
    case userConstants.GET_MEMBER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case billingConstants.REMOVE_CARD_SUCCESS: 
      return { ...state, paymentAccounts: action.paymentAccounts, error: null}
    case billingConstants.PAYMENT_INFO_SAVE_SUCCESS: 
      return { ...state, paymentAccounts: action.paymentAccounts, error: null}
    default:
      return state
  }
}

export const selectVerified = (state) => state.member.isVerified;
export const selectPublicToken = (state) => state.member.publicToken;
// why are these arrays different??
export const selectEmailDomains = (state) => state.member.emailDomains;
export const selectEmailDomainsPendingSetup = (state) => [state.member.emailDomainsPendingSetup];
export const selectPaymentAccounts = (state) => state.member.paymentAccounts;
export const selectMemberLoaded = (state) => state.member.isMemberLoaded;
export const selectMemberReplyActive = (state) => state.member.isReplyActive;
export const selectMemberAttachActive = (state) => state.member.isAttachActive;
export const selectMemberAutoCreateActive = (state) => state.member.isAutoCreateActive;