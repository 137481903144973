import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Container, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ServicePlanSubscriptionCard, CustomDomainSubscriptionCard } from '../../components/Subscription'
import { billingActions } from '../../actions'
import { paths } from '../../constants'
import { selectPlan } from 'src/redux/modules/planReducer';
import { selectSubscription } from 'src/redux/modules/subscriptionReducer';

const ManageSubscriptionElement = () => {

  const dispatch = useDispatch();
  const subscriptionState = useSelector(selectSubscription)
  const planState = useSelector(selectPlan);

  const servicePlan = planState.servicePlan;
  const isSubscriptionActive = subscriptionState.isSubscriptionActive;
  const isCustomDomainSubscription = subscriptionState.isCustomDomainSubscription;

  const [modal, setModal] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [targetPlanId, setTargetPlanId] = useState(null);

  const openSubActionConfirm = (isCancel, targetPlanId) => {
    setModal(true);
    setIsCancel(isCancel);
    setTargetPlanId(targetPlanId);
  }

  const toggle = () => {
    setModal(!modal);
    setIsCancel(null);
  }

  const handleDowngrade = () => {
    toggle();
    dispatch(billingActions.downgradeService(isCancel, targetPlanId));
  }

  if (!servicePlan) {
    return <div></div>
  }

  let endDateString = ""
  if (subscriptionState.endDate) {
    const endDate = new Date(subscriptionState.endDate);
    endDateString = (endDate.getMonth()+1) + '/' +  endDate.getDay() + '/' + (1900+endDate.getYear())
  }

  if (isSubscriptionActive || subscriptionState.endDate) {
    return (
      <div>
        {subscriptionState.endDate && <Alert color="warning">Subscription cancelled. Premium functionality available until {endDateString}</Alert>}
        <Container>
          {!isCustomDomainSubscription && <ServicePlanSubscriptionCard isSubscribeLink={false}/>}
          {isCustomDomainSubscription && <CustomDomainSubscriptionCard isSubscribeLink={false}/>}
          <br/>
          {!subscriptionState.endDate && <Button color="danger" onClick={e => openSubActionConfirm(true, null)}>Cancel subscription</Button> }
          &nbsp;
          {isCustomDomainSubscription && !subscriptionState.endDate && <Button onClick={e => openSubActionConfirm(false, servicePlan.id)}>Downgrade to a plan without a custom domain</Button>}            
        </Container>     
        <Modal isOpen={modal} toggle={e => toggle()}>
          <ModalHeader toggle={toggle}>Danger!</ModalHeader>
          <ModalBody>
            {isCancel && <p>Are you sure you want to cancel the subscription?</p>}
            {!isCancel && <p>Are you sure you want to downgrade?</p>}
            {isCustomDomainSubscription && <p>Once the subscription period has ended, all virtual emails for your custom domain will cease to work.</p>}
          </ModalBody>
          <ModalFooter>
            {isCancel && <Button color="danger" onClick={e => handleDowngrade()}>Really cancel subscription</Button>}
            {!isCancel && <Button color="danger" onClick={e => handleDowngrade()}>Downgrade</Button>}
            <Button color="secondary" onClick={toggle}>Back to safety</Button>
          </ModalFooter>
        </Modal>     
      </div>
    )
  } else {
    return (
      <Container>
        You are currently not subscribed to any plans. Visit <Link to={paths.subscriptionLandingPage}>the plans page to see available plans.</Link>
      </Container>
    )
  }
}
export default ManageSubscriptionElement;