import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { virtualEmailActions } from '../../actions'
import { 
  Button, 
  Spinner, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  DropdownToggle, 
  DropdownMenu, 
  DropdownItem, 
  ButtonDropdown,
  Container,
  Card,
  CardBody
} from 'reactstrap';
import AddVirtualEmailForm from '../../components/AddVirtualEmailForm/AddVirtualEmailForm';
import { selectVirtualEmails } from 'src/redux/modules/virtualEmailReducer';

const ListVirtualEmailTable = () => {
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [modal, setModal] = useState(false);
  const [virtualEmailIdToDelete, setVirtualEmailIdToDelete] = useState(null);
  const [virtualEmailAddressToDelete, setVirtualEmailAddressToDelete] = useState(null);
  const virtualEmailState = useSelector(selectVirtualEmails);

  const dispatch = useDispatch();

  const virtualEmails = virtualEmailState.items;
  const loading = virtualEmailState.loading;
  const error = virtualEmailState.error;
  const adding = virtualEmailState.adding;

  useEffect(() => {
    dispatch(virtualEmailActions.getVirtualEmails(false));
  }, [dispatch]);

  const toggle = () => {
    setModal(!modal);
  };

  const openDeleteConfirm = (virtualEmailId, virtualEmailAddress) => {
    setModal(!modal);
    setVirtualEmailIdToDelete(virtualEmailId);
    setVirtualEmailAddressToDelete(virtualEmailAddress);
  };

  const toggleDropdown = (virtualEmailId) => {
    setDropdownOpen(prevState => ({
      ...prevState,
      [virtualEmailId]: !prevState[virtualEmailId]
    }));
  };

  const handleRemove = () => {
    toggle();
    dispatch(virtualEmailActions.removeVirtualEmail(virtualEmailIdToDelete))
      .then(() => dispatch(virtualEmailActions.getVirtualEmails(false)));
  };

  const handleActivate = (virtualEmailId) => {
    dispatch(virtualEmailActions.activateVirtualEmail(virtualEmailId))
  }

  const handleDeactivate = (virtualEmailId) => {
    dispatch(virtualEmailActions.deactivateVirtualEmail(virtualEmailId))
  }

  const isDropdownOpen = (virtualEmailId) => {
    return dropdownOpen[virtualEmailId]
  }

  const copyToClipboard = (virtualEmailAddress) => {
    navigator.clipboard.writeText(virtualEmailAddress)
  }

  if (error) {
    return <div>Error! {error.message}</div>;
  }

  if (loading) {
    return <div><Spinner style={{ width: '3rem', height: '3rem' }} type="grow" /></div>
  }

  return (
    <div>
      { (virtualEmails === undefined || virtualEmails.length === 0) &&
        <div>
          <div className="rounded px-3 px-sm-4 py-3 py-sm-5">
            <h1 className="display-4">Your account is confirmed</h1>
            <p className="lead">Now, create a virtual email below</p>
          </div>
          {adding ? <Spinner type="grow" color="primary" /> : ""}
          <Container>
            <AddVirtualEmailForm />
          </Container>
        </div>
      } 
      { virtualEmails !== undefined && virtualEmails.length > 0 &&
        <div>
        
          <Container>
            <AddVirtualEmailForm />
          </Container>
          <br/>
          <Container>          
            Forwarding
            <Card>
              <CardBody>
                <Table hover size="sm" striped>
                  <tbody>
                    {virtualEmails.map(virtualEmail =>
                      <React.Fragment key={virtualEmail.id}>
                        <tr key={virtualEmail.id}>
                          <td>
                            <ButtonDropdown isOpen={isDropdownOpen(virtualEmail.id)} toggle={e => toggleDropdown(virtualEmail.id)}> 
                              <DropdownToggle color="link">
                                {virtualEmail.isActive && virtualEmail.virtualEmailAddress}
                                {!virtualEmail.isActive && <span style={{ color: 'grey', fontStyle: 'italic', 'verticalAlign': 'middle' }}>{virtualEmail.virtualEmailAddress}</span>}
                              </DropdownToggle>
                              <DropdownMenu>
                                {virtualEmail.isActive && <DropdownItem onClick={e => handleDeactivate(virtualEmail.id)}>Deactivate</DropdownItem>}
                                {!virtualEmail.isActive && <DropdownItem onClick={e => handleActivate(virtualEmail.id)}>Activate</DropdownItem>}
                                <DropdownItem onClick={e => openDeleteConfirm(virtualEmail.id, virtualEmail.virtualEmailAddress)}>Delete</DropdownItem>
                                <DropdownItem onClick={e => copyToClipboard(virtualEmail.virtualEmailAddress)}>Copy to clipboard</DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          </td>
                          <Modal isOpen={modal} toggle={e => toggle(virtualEmail.id)} >
                            <ModalHeader>Danger!</ModalHeader>
                            <ModalBody>
                          Are you sure you want to delete {virtualEmailAddressToDelete}? Deletion is permanent and will stop forwarding immediately to this address.
                            </ModalBody>
                            <ModalFooter>
                              <Button color="danger" onClick={e => handleRemove()}>Really delete</Button>{' '}
                              <Button color="secondary" onClick={toggle}>Cancel</Button>
                            </ModalFooter>
                          </Modal>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
          {adding && 
            <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
          }
        </div>
      }
    </div>
  );
}

export default ListVirtualEmailTable;