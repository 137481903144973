import React from 'react';
import { useSelector } from 'react-redux';
import VerifyEmailForm from '../../components/VerifyEmailForm/VerifyEmailForm';
import ChangePasswordForm from '../../components/ChangePasswordForm/ChangePasswordForm';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { paths } from '../../constants';
import { selectVerified } from 'src/redux/modules/memberReducer';

const VerifyAccount = () => {

  const isVerified = useSelector(selectVerified);

  return (
    <div className="container">
      {isVerified && <Nav tabs>
        <NavItem>
          <NavLink className="defaultFont" tag={RRNavLink} to={paths.mailSettings}>Settings</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="defaultFont" tag={RRNavLink} to={paths.changePassword}>Change Password</NavLink>
        </NavItem>
      </Nav>}
      {!isVerified && <Nav tabs>
        <NavItem> 
          <NavLink  className="defaultFont" tag={RRNavLink} to={paths.verifyEmail} active>Verify Email</NavLink>
        </NavItem>
      </Nav>}
      {!isVerified && <VerifyEmailForm/>} 
      {isVerified && <ChangePasswordForm/>}
    </div>
  );
}

export default VerifyAccount;