import { domainConstants } from '../../constants';

const initialState = {
  loading: null,
  error: null,
  domainName: null,
  isDomainAvailable: false,
  IsRegistrationInProcess: false,
  suggestions: [],
};

export function domain(state = initialState, action) {
  switch (action.type) {
    case domainConstants.SUBMIT_DOMAIN_REQUEST:
      return { ...state, domainName: action.domainName, loading: true, error: null, suggestions: [] };
    case domainConstants.SUBMIT_DOMAIN_SUCCESS:
      return { ...state, domainName: action.domainName, isDomainAvailable: true, loading: false, error: null, 
        isRegistrationInProcess: action.isRegistrationInProcess};
    case domainConstants.SUBMIT_DOMAIN_UNAVAILABLE:
      return { ...state, domainName: action.domainName, suggestions: action.suggestions, loading: false, error: null };
    case domainConstants.SUBMIT_DOMAIN_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state
  }
}

export const selectDomain = (state) => state.domain;