import { userConstants } from '../../constants';
import { billingConstants } from '../../constants';

const initialState = {
  isSubscriptionActive: false,
  isCustomDomainSubscription: false,
  currentPlan: null,
  isRegInProcess: false,
  endDate: null
};

export function subscription(state = initialState, action) {
  let isSubscriptionActive = false;
  let isCustomDomainSubscription = false;
  switch (action.type) {
    case userConstants.GET_MEMBER_SUCCESS:
      let plan = null;
      let endDate = null;
      if (action.summary.subscriptions) {
        for (const sub of action.summary.subscriptions) {
          if (sub.plan.priceInCents > 0) {
            isSubscriptionActive = true;
            if (sub.plan.subscriptionType === 'CUSTOM_DOMAIN') {
              isCustomDomainSubscription = true
            }
            endDate = sub.endDate
          }
          plan = sub.plan;
        }
      }
      return { ...state, loading: false, error: null, 
        isSubscriptionActive: isSubscriptionActive,
        isCustomDomainSubscription: isCustomDomainSubscription,
        currentPlan: plan, endDate: endDate
      };
    case billingConstants.PURCHASE_SUCCESS:
      if (action.subscription.plan.priceInCents > 0) {
        isSubscriptionActive = true;
        if (action.subscription.plan.subscriptionType === 'CUSTOM_DOMAIN') {
          isCustomDomainSubscription = true
        }
      }
      return { ...state, loading: false, error: null, 
        isSubscriptionActive: isSubscriptionActive,
        isCustomDomainSubscription: isCustomDomainSubscription,
        currentPlan: action.subscription.plan,
        isRegInProcess: action.isRegInProcess,
        endDate: action.subscription.endDate
      };
    case billingConstants.DOWNGRADE_SUCCESS:
      if (action.subscription.plan.priceInCents > 0) {
        isSubscriptionActive = true;
        if (action.subscription.plan.subscriptionType === 'CUSTOM_DOMAIN') {
          isCustomDomainSubscription = true
        }
      }
      return { ...state, loading: false, error: null, 
        isSubscriptionActive: isSubscriptionActive,
        isCustomDomainSubscription: isCustomDomainSubscription,
        currentPlan: action.subscription.plan,
        endDate: action.subscription.endDate
      };  
    default:
      return state
  }
}

export const selectSubscription = (state) => state.subscription;
