import React from 'react';
import { useSelector } from 'react-redux';
import RegisterDomainForm from '../../components/RegisterDomainForm/RegisterDomainForm';
import CustomDomainSetupElement from '../../components/CustomDomainElement/CustomDomainSetupElement'
import CustomDomainAlreadyExistsElement from '../../components/CustomDomainElement/CustomDomainAlreadyExistsElement'
import { Spinner } from 'reactstrap';
import { selectPlan } from '../../redux/modules/planReducer'
import { selectBilling } from 'src/redux/modules/billingReducer';
import { selectSubscription } from 'src/redux/modules/subscriptionReducer';
import { selectDomain } from 'src/redux/modules/domainReducer';

const RegisterDomainContainer = () => {
  
  const plan = useSelector(selectPlan);
  const domainState = useSelector(selectDomain);
  const subscription = useSelector(selectSubscription);
  const billing = useSelector(selectBilling);

  if (!plan.customDomainPlan) {
    return <div></div>
  }

  const priceInDollars = plan.customDomainPlan.priceInCents/100;
  const messages = [`Purchase ${domainState.domainName} for $${priceInDollars} each year.`];

  if (subscription.isSubscriptionActive && subscription.isCustomDomainSubscription && subscription.isRegInProcess) {
    return (
      <div><CustomDomainSetupElement/></div>
    )
  } else if (subscription.isSubscriptionActive && subscription.isCustomDomainSubscription) {
    return (
      <div><CustomDomainAlreadyExistsElement/></div>
    )    
  } else  {
    return (
      <div className="container">
        <RegisterDomainForm messages={messages}/>
        {billing.loading &&
          <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
        }
      </div>      
    );  
  }
}

export default RegisterDomainContainer;