export const billingConstants = {
  DOWNGRADE_REQUEST: 'DOWNGRADE_REQUEST',
  DOWNGRADE_SUCCESS: 'DOWNGRADE_SUCCESS',
  DOWNGRADE_ERROR: 'DOWNGRADE_ERROR',
  PAYMENT_INFO_REQUIRED: 'PAYMENT_INFO_REQUIRED',
  PAYMENT_INFO_SAVE_REQUEST: 'PAYMENT_INFO_SAVE_REQUEST',
  PAYMENT_INFO_SAVE_SUCCESS: 'PAYMENT_INFO_SAVE_SUCCESS',
  UPDATE_CART: 'UPDATE_CART',
  UPDATE_CARD: 'UPDATE_CARD',
  CANCEL_UPDATE_CARD: 'CANCEL_UPDATE_CARD',
  PAYMENT_INFO_SUB_ERROR: 'PAYMENT_INFO_SUB_ERROR',
  PAYMENT_INFO_SAVE_ERROR: 'PAYMENT_INFO_SAVE_ERROR',
  PAYMENT_INFO_PURCHASE_REQUEST: 'PAYMENT_INFO_SAVE_REQUEST',
  PURCHASE_SUB_ERROR: 'PURCHASE_SUB_ERROR',
  PURCHASE_ERROR: 'PURCHASE_ERROR',
  PURCHASE_SAVE_ERROR: 'PURCHASE_SAVE_ERROR',
  PURCHASE_REQUEST: 'PURCHASE_REQUEST',
  PURCHASE_SUCCESS: 'PURCHASE_SUCCESS',
  REMOVE_CARD_REQUEST: 'REMOVE_CARD_REQUEST',
  REMOVE_CARD_SUCCESS: 'REMOVE_CARD_SUCCESS',
  REMOVE_CARD_ERROR: 'REMOVE_CARD_ERROR',
  OPEN_PAYMENT_MODAL: 'OPEN_PAYMENT_MODAL',
  CLOSE_PAYMENT_MODAL: 'CLOSE_PAYMENT_MODAL',
}