export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  ADD_VIRTUAL_EMAIL_REQUEST: 'ADD_VIRTUAL_EMAIL_REQUEST',
  ADD_VIRTUAL_EMAIL_SUCCESS: 'ADD_VIRTUAL_EMAIL_SUCCESS',
  ADD_VIRTUAL_EMAIL_FAILURE: 'ADD_VIRTUAL_EMAIL_FAILURE',

  VERIFY_TOKEN_REQUEST: 'VERIFY_TOKEN_REQUEST',
  VERIFY_TOKEN_SUCCESS: 'VERIFY_TOKEN_SUCCESS',
  VERIFY_TOKEN_FAILURE: 'VERIFY_TOKEN_FAILURE',
  VERIFY_TOKEN_MISMATCH: 'VERIFY_TOKEN_MISMATCH',

  RESEND_TOKEN_SUCCESS: 'RESEND_TOKEN_SUCCESS',
  RESEND_TOKEN_FAILURE: 'RESEND_TOKEN_FAILURE',

  GET_VIRTUAL_EMAILS_REQUEST: 'GET_VIRTUAL_EMAILS_REQUEST',
  GET_VIRTUAL_EMAILS_SUCCESS: 'GET_VIRTUAL_EMAILS_SUCCESS',
  GET_VIRTUAL_EMAILS_FAILURE: 'GET_VIRTUAL_EMAILS_FAILURE',

  ADD_PENDING_VIRTUAL_EMAIL_REQUEST: 'ADD_PENDING_VIRTUAL_EMAIL_REQUEST',

  REMOVE_VIRTUAL_EMAIL_REQUEST: 'REMOVE_VIRTUAL_EMAIL_REQUEST',
  REMOVE_VIRTUAL_EMAIL_SUCCESS: 'REMOVE_VIRTUAL_EMAIL_SUCCESS',
  REMOVE_VIRTUAL_EMAIL_FAILURE: 'REMOVE_VIRTUAL_EMAIL_FAILURE',

  ACTIVATE_VIRTUAL_EMAIL_REQUEST: 'ACTIVATE_VIRTUAL_EMAIL_REQUEST',
  ACTIVATE_VIRTUAL_EMAIL_SUCCESS: 'ACTIVATE_VIRTUAL_EMAIL_SUCCESS',
  ACTIVATE_VIRTUAL_EMAIL_FAILURE: 'ACTIVATE_VIRTUAL_EMAIL_FAILURE',

  DEACTIVATE_VIRTUAL_EMAIL_REQUEST: 'DEACTIVATE_VIRTUAL_EMAIL_REQUEST',
  DEACTIVATE_VIRTUAL_EMAIL_SUCCESS: 'DEACTIVATE_VIRTUAL_EMAIL_SUCCESS',
  DEACTIVATE_VIRTUAL_EMAIL_FAILURE: 'DEACTIVATE_VIRTUAL_EMAIL_FAILURE',

  ENABLE_REPLY_REQUEST: 'ENABLE_REPLY_REQUEST',
  ENABLE_REPLY_SUCCESS: 'ENABLE_REPLY_SUCCESS',
  ENABLE_REPLY_FAILURE: 'ENABLE_REPLY_FAILURE',

  DISABLE_REPLY_REQUEST: 'DISABLE_REPLY_REQUEST',
  DISABLE_REPLY_SUCCESS: 'DISABLE_REPLY_SUCCESS',
  DISABLE_REPLY_FAILURE: 'DISABLE_REPLY_FAILURE',

  ENABLE_ATTACH_REQUEST: 'ENABLE_ATTACH_REQUEST',
  ENABLE_ATTACH_SUCCESS: 'ENABLE_ATTACH_SUCCESS',
  ENABLE_ATTACH_FAILURE: 'ENABLE_ATTACH_FAILURE',

  DISABLE_ATTACH_REQUEST: 'DISABLE_ATTACH_REQUEST',
  DISABLE_ATTACH_SUCCESS: 'DISABLE_ATTACH_SUCCESS',
  DISABLE_ATTACH_FAILURE: 'DISABLE_ATTACH_FAILURE',

  DISABLE_AUTO_CREATE_REQUEST: 'DISABLE_AUTO_CREATE_REQUEST',
  DISABLE_AUTO_CREATE_SUCCESS: 'DISABLE_AUTO_CREATE_SUCCESS',
  DISABLE_AUTO_CREATE_FAILURE: 'DISABLE_AUTO_CREATE_FAILURE',

  ENABLE_AUTO_CREATE_REQUEST: 'ENABLE_AUTO_CREATE_REQUEST',
  ENABLE_AUTO_CREATE_SUCCESS: 'ENABLE_AUTO_CREATE_SUCCESS',
  ENABLE_AUTO_CREATE_FAILURE: 'ENABLE_AUTO_CREATE_FAILURE',

  GET_MEMBER_REQUEST: 'GET_MEMBER_REQUEST',
  GET_MEMBER_SUCCESS: 'GET_MEMBER_SUCCESS',
  GET_MEMBER_FAILURE: 'GET_MEMBER_FAILURE',

  PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',
  
  PASSWORD_RESET_SUBMIT_REQUEST: 'PASSWORD_RESET_SUBMIT_REQUEST',
  PASSWORD_RESET_SUBMIT_SUCCESS: 'PASSWORD_RESET_SUBMIT_SUCCESS',
  PASSWORD_RESET_SUBMIT_FAILURE: 'PASSWORD_RESET_SUBMIT_FAILURE',
  
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE'
  
};