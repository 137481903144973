import { userConstants } from '../../constants';

const initialState = {
  virtualEmail: {
    name: '',
    domainId: null
  },
  items: [],
  loading: false,
  error: null,
  adding: false,
  addError: null
};

export function virtualEmails(state = initialState, action) {
  const newitems = [];
  switch (action.type) {
    case userConstants.GET_VIRTUAL_EMAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.GET_VIRTUAL_EMAILS_SUCCESS:
      return { ...state, items: action.items, loading: false, error: null };
    case userConstants.GET_VIRTUAL_EMAILS_FAILURE:
      return { ...state, loading: false, error: action.error, items: [] };
    case userConstants.ADD_VIRTUAL_EMAIL_REQUEST:
      return { ...state, adding: true, error: null };
    case userConstants.ADD_VIRTUAL_EMAIL_SUCCESS:
      for (let i = 0; i < state.items.length; i++) {
        newitems.push(state.items[i]);
      }
      newitems.push(action.virtualEmail);
      state.items = newitems;
      // clear out virtualEmail now that it has been added, I think?
      return { ...state, virtualEmail: null, loading: false, error: null, adding: false, addError: null };
    case userConstants.ADD_VIRTUAL_EMAIL_FAILURE:
      return { ...state, loading: false, error:null, addError: action.error.message, virtualEmail: null, adding: false };
    case userConstants.REMOVE_VIRTUAL_EMAIL_REQUEST:
      return { ...state, loading: true, error: null };
    case userConstants.REMOVE_VIRTUAL_EMAIL_SUCCESS:
      for (let i = 0; i < state.items.length; i++) {
        const virtualEmail = state.items[i]
        if (virtualEmail.id !== action.virtualEmailId) {
          newitems.push(virtualEmail);
        }
      }
      state.items = newitems;      
      return { ...state, virtualEmail: null, loading: false, error: null, items: state.items };
    case userConstants.REMOVE_VIRTUAL_EMAIL_FAILURE:
      return { ...state, loading: false, error: action.error.message, virtualEmail: null };
    case userConstants.ACTIVATE_VIRTUAL_EMAIL_REQUEST: 
      return { ...state, loading: true, error: null };
    case userConstants.ACTIVATE_VIRTUAL_EMAIL_SUCCESS:
      for (let i = 0; i < state.items.length; i++) {
        const virtualEmail = state.items[i]
        if (virtualEmail.id !== action.virtualEmail.id) {
          newitems.push(virtualEmail);
        } else {
          newitems.push(action.virtualEmail);
        }
      }
      state.items = newitems;  
      return { ...state, virtualEmail: null, loading: false, error: null, items: state.items };          
    case userConstants.ACTIVATE_VIRTUAL_EMAIL_FAILURE:
      return { ...state, loading: false, error: action.error.message, virtualEmail: null };
    case userConstants.DEACTIVATE_VIRTUAL_EMAIL_REQUEST: 
      return { ...state, loading: true, error: null };
    case userConstants.DEACTIVATE_VIRTUAL_EMAIL_SUCCESS:
      for (let i = 0; i < state.items.length; i++) {
        const virtualEmail = state.items[i]
        if (virtualEmail.id !== action.virtualEmail.id) {
          newitems.push(virtualEmail);
        } else {
          newitems.push(action.virtualEmail);
        }
      }
      state.items = newitems;  
      return { ...state, virtualEmail: null, loading: false, error: null, items: state.items };          
    case userConstants.DEACTIVATE_VIRTUAL_EMAIL_FAILURE:
      return { ...state, loading: false, error: action.error.message, virtualEmail: null };
    default:
      return state
  }
}
export const selectVirtualEmails = (state) => state.virtualEmails;