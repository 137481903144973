import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, ListGroupItem, ListGroup } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { paths } from '../../constants'
import { billingActions } from '../../actions'
import { selectPlan } from 'src/redux/modules/planReducer';

const CustomDomainSubscriptionCard = (isSubscribeLink) => {

  const dispatch = useDispatch();
  const planState = useSelector(selectPlan);
  const customDomainPlan = planState.customDomainPlan;
  const subscribeUrl = paths.customDomainSubscribe + "?planId=" + customDomainPlan.id;

  const handleSubscribeClick = () => {
    dispatch(billingActions.updateCart(planState.customDomainPlan.id, null));
  }
  
  if (!customDomainPlan) {
    return <div></div>
  }

  const priceInDollars = customDomainPlan.priceInCents/100;
  const linkText = `Sign up for $${priceInDollars} ${customDomainPlan.billingFrequency.toLowerCase()}`

  return (
    <div>         
      <Card> 
        <CardBody>            
          <h4>Pick Your Own Email with a Custom Domain</h4>
          <br/>
          {isSubscribeLink.isSubscribeLink && <Button block onClick={e => handleSubscribeClick()} color="primary" tag={RRNavLink} to={subscribeUrl}>{linkText}</Button>}
          {isSubscribeLink.isSubscribeLink && <br/>}
          <ListGroup flush>
            <ListGroupItem>&#10004;&nbsp;Your own domain: "whatyouwant.com"</ListGroupItem>
            <ListGroupItem>&#10004;&nbsp;Up to {customDomainPlan.maxVirtualEmails.toLocaleString()} virtual emails</ListGroupItem>
            <ListGroupItem>&#10004;&nbsp;Create virtual emails automatically</ListGroupItem>
            <ListGroupItem>&#10004;&nbsp;Attachment forwarding</ListGroupItem>
            <ListGroupItem>&#10004;&nbsp;Public breach database scanning</ListGroupItem>
          </ListGroup>
        </CardBody>            
      </Card>
    </div>
  );
}

export { CustomDomainSubscriptionCard };