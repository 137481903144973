import React from 'react';
import { Alert, Card, CardBody } from 'reactstrap';
import { selectEmailDomains } from 'src/redux/modules/memberReducer';

const CustomDomainAlreadyExistsElement = () => {
  
  const memberEmailDomains = selectEmailDomains();

  let trailer = ""
  if (memberEmailDomains && memberEmailDomains.length > 0) {
    const memberDomain = memberEmailDomains[0]
    trailer = `Your account is currently setup with ${memberDomain.domainName}`
  }
  return (
    <div>         
      <Card body>
        <CardBody>
          <Alert color="success">
            <h4 className="alert-heading">Subscription for custom domain is already active.</h4>
            <p>
              Subscriptions are currently restricted to a single domain.                
            </p>
            <p>
              {trailer}
            </p>
            <hr />
            <p className="mb-0">
              If you need more domains. Please contact us (click the Help button below.)
            </p>
          </Alert>
        </CardBody>
      </Card>
    </div>
  );
}

export default CustomDomainAlreadyExistsElement;