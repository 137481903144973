import { billingConstants, domainConstants } from '../constants';
import { userService } from '../services'
import { alertActions } from './';


export const domainActions = {
  registerDomain,
}

function registerDomain(domainName) {
  return dispatch => {
    dispatch(alertActions.clear());
    dispatch(request(domainName));
    return userService.registerDomain(domainName).then(res => {
      if (res.suggestions) {
        dispatch(alertActions.success(`We're sorry, ${domainName} is not available.`));
        dispatch(domainUnavailable(domainName, res.suggestions))
      } else {
        dispatch(alertActions.success(`Domain ${domainName} is available!`));
        if (res.isPaymentInfoRequired) {
          dispatch(paymentRequired(domainName));
        }
        dispatch(success(domainName));
      }
    }).catch(err => {
      if (err.message) {
        dispatch(alertActions.error(err.message))
      } else {
        dispatch(alertActions.error("Unable to process domain."))
      }
      dispatch(failure(err));
    })
    function request(domainName) { return { type: domainConstants.SUBMIT_DOMAIN_REQUEST, domainName } }
    function success(domainName) { return { type: domainConstants.SUBMIT_DOMAIN_SUCCESS, domainName } }
    function domainUnavailable(domainName, suggestions) { return { type: domainConstants.SUBMIT_DOMAIN_UNAVAILABLE, domainName, suggestions } }
    function paymentRequired(domainName) { return { type: billingConstants.PAYMENT_INFO_REQUIRED, domainName } }
    function failure(error) { return { type: domainConstants.SUBMIT_DOMAIN_FAILURE, error } }
  }
}