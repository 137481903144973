import { billingConstants } from '../constants';
import { alertActions } from './';
import { userService } from '../services'

export const billingActions = {
  saveCreditCard, purchase, updateCart, downgradeService, removeCard, updateCard, cancelUpdateCard, openPaymentModal, closePaymentModal
}

function saveCreditCard(token, billingAddressZip, billingAddressCountry, creditCardBrand, creditCardExpMonth, creditCardExpYear,
  creditCardLastFour, planId, domainName) {
  return dispatch => {
    dispatch(request());
    return userService.billingSave(token, billingAddressZip, billingAddressCountry, creditCardBrand, creditCardExpMonth, creditCardExpYear,
      creditCardLastFour).then(res => {
      if (res.isSaveCardSuccess) {
        if (planId) {
          dispatch(purchase(planId, domainName));
          dispatch(success(res.paymentAccounts, res.domainName));  
        } else {
          dispatch(success(res.paymentAccounts));  
        }
      } else {
        dispatch(alertActions.error("Payment failed. Please check your credit card number and try again."))
        dispatch(paymentRequired(res.domainName));
      }
    }).catch(err => {
      dispatch(alertActions.error("Unable to process card."))
      dispatch(failure(err));
    })
    function request() { return { type: billingConstants.PAYMENT_INFO_SAVE_REQUEST } }
    function success(paymentAccounts, domainName) { return { type: billingConstants.PAYMENT_INFO_SAVE_SUCCESS, paymentAccounts, domainName } }
    function paymentRequired(domainName) { return { type: billingConstants.PAYMENT_INFO_REQUIRED, domainName } }
    function failure(error) { return { type: billingConstants.PAYMENT_INFO_SAVE_ERROR, error } }
  }
}

function purchase(planId, domainName) {
  return dispatch => {
    dispatch(request());
    return userService.billingPurchase(planId, domainName).then(res => {
      if (res.subscription && res.subscription.plan.priceInCents > 0) {
        if (domainName) {
          dispatch(alertActions.success("Payment received. Registration in progress."))
        } else {
          dispatch(alertActions.success("Payment received. Subscription activated"))
        }
        dispatch(success(res.subscription, res.domainName, res.isRegInProcess));
      } else {
        if (domainName) {
          dispatch(alertActions.error("Unable to register domain."))
        } else {
          dispatch(alertActions.error("Unable to subscribe."))
        }
        dispatch(subFailure());        
      } 
    }).catch(err => {
      if (err.message) {
        dispatch(alertActions.error(err.message))
      } else {
        if (domainName) {
          dispatch(alertActions.error("Unable to purchase domain."))
        } else {
          dispatch(alertActions.error("Unable to subscribe."))
        }  
      }
      dispatch(failure(err));
    })
    function request() { return { type: billingConstants.PURCHASE_REQUEST } }
    function success(subscription, domainName, isRegInProcess) { return { type: billingConstants.PURCHASE_SUCCESS, subscription, domainName, isRegInProcess } }
    function subFailure() { return { type: billingConstants.PURCHASE_SUB_ERROR } }
    function failure(error) { return { type: billingConstants.PURCHASE_ERROR, error } }
  }
}

function downgradeService(isCancel, targetPlanId) {
  return dispatch => {
    dispatch(request());
    return userService.downgradeService(isCancel, targetPlanId).then(res => {
      dispatch(alertActions.success("Subscription update complete."))
      dispatch(success(res.subscription));
    }).catch(err => {
      console.log(err)
      if (err.message) {
        dispatch(alertActions.error(err.message))
      } else {
        dispatch(alertActions.error("Unable to update subscription."))
      }
      dispatch(failure(err));
    })
    function request() { return { type: billingConstants.DOWNGRADE_REQUEST } }
    function success(subscription ) { return { type: billingConstants.DOWNGRADE_SUCCESS, subscription } }
    function failure(error) { return { type: billingConstants.DOWNGRADE_ERROR, error } }
  }
}

function updateCart(planId, domainName) {
  return dispatch => {
    dispatch({ type: billingConstants.UPDATE_CART, planIdToPurchase: planId, domainToPurchase: domainName });
  }
}

function updateCard(paymentAccountId) {
  return dispatch => {
    dispatch({ type: billingConstants.UPDATE_CARD, paymentAccountId: paymentAccountId });
  }
}

function cancelUpdateCard(paymentAccountId) {
  return dispatch => {
    dispatch({ type: billingConstants.CANCEL_UPDATE_CARD, paymentAccountId: paymentAccountId });
  }
}

function removeCard(paymentAccountId) {
  return dispatch => {
    dispatch(request());
    return userService.removeCard(paymentAccountId).then(res => {
      dispatch(alertActions.success("Card removed."))
      dispatch(success(res.paymentAccounts));
    }).catch(err => {
      dispatch(alertActions.error(err.toString()))
      dispatch(failure(err));      
    })
    function request() { return { type: billingConstants.REMOVE_CARD_REQUEST } }
    function success(paymentAccounts) { return { type: billingConstants.REMOVE_CARD_SUCCESS, paymentAccounts } }
    function failure(error) { return { type: billingConstants.REMOVE_CARD_ERROR, error } }
  }
}

function closePaymentModal() {
  return dispatch => {
    dispatch({ type: billingConstants.CLOSE_PAYMENT_MODAL });
  }
}

function openPaymentModal() {
  return dispatch => {
    dispatch({ type: billingConstants.OPEN_PAYMENT_MODAL });
  }
}