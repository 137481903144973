// require('babel-polyfill');
import "core-js/stable";


const environment = {
  development: {
    isProduction: false
  },
  production: {
    isProduction: true
  }
}[process.env.NODE_ENV || 'development'];

const development = {
  cognito: {
    userPoolId: 'us-west-2_naMOLuAIp',
    userPoolWebClientId: '1gar8la653lr2f7328jshh5huh',
  },
  awsRegion: 'us-west-2',
  baseApiUrl: 'https://api.layeredmaskdev.com',
  pixelBaseUrl: 'https://px.layeredmaskdev.com/p.gif?siteId=b0e17a',
  stripePublishableKey: 'pk_test_j0GkP3GJfbSLAOQWLigfhb6J00SNYfmUtd'
};

const production = {
  cognito: {
    userPoolId: 'us-west-2_CSRjTfEn7',
    userPoolWebClientId: '1rq321e3qr5dksm8ha1uoohm8a',
  },
  awsRegion: 'us-west-2',
  baseApiUrl: 'https://api.idunseen.com',
  pixelBaseUrl: 'https://px.idunseen.com/p.gif?siteId=3aa120',
  stripePublishableKey: 'pk_live_DysVQixKb66G7dhDRT05MJdt00U59XiVi7'
};

const envConfig = process.env.REACT_APP_STAGE === 'production'
  ? production
  : development;

export default Object.assign({
  host: process.env.HOST || 'localhost',
  port: process.env.PORT,
  apiHost: process.env.APIHOST || 'localhost',
  apiPort: process.env.APIPORT,
  cognito: envConfig.cognito,
  awsRegion: envConfig.awsRegion,
  baseApiUrl: envConfig.baseApiUrl,
  pixelBaseUrl: envConfig.pixelBaseUrl,
  stripePublishableKey: envConfig.stripePublishableKey,

  app: {
    title: 'IDUnseen',
    description: 'IDUnseen',
    head: {
      titleTemplate: 'IDUnseen: %s',
      meta: [
        {name: 'description', content: 'IDUnseen.'},
        {charset: 'utf-8'},
        {property: 'og:site_name', content: 'IDUnseen'},
        {property: 'og:locale', content: 'en_US'},
        {property: 'og:title', content: 'IDUnseen'},
        {property: 'og:description', content: 'IDUnseen'},

      ]
    }
  },

}, environment);

// export default module.exports;