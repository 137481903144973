export const paths = {
  account: '/account/',
  changePassword: '/account/changePassword/',
  customDomainSubscribe: '/subscribe/customDomain/',
  forwarding: '/forwarding/',
  login: '/login/',
  mailSettings: '/account/mailSettings/',
  register: '/register/',
  serviceSubscribe: '/subscribe/service/',
  subscriptionLandingPage: '/subscribe/',
  subscriptionSettings: '/account/subscription/',
  paymentSettings: '/account/payment/',
  verifyEmail: '/account/verify/', 
}