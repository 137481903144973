import React, { useState } from 'react';
import { Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { paths } from '../../constants'
import { NavLink as RRNavLink } from 'react-router-dom';


const AccountSettingsNav = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen(!isDropdownOpen);
  }

  return (
    <div className="container defaultFont">
      <Nav className="ms-auto" tabs>
        <NavItem>
          <NavLink tag={RRNavLink} to={paths.mailSettings}>Mail Settings</NavLink>
        </NavItem>
        <Dropdown nav isOpen={isDropdownOpen} toggle={toggle}> 
          <DropdownToggle nav caret>
            Account Settings
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem disabled>Verify Email</DropdownItem>
            <DropdownItem tag={RRNavLink} to={paths.changePassword}>Change Password</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <NavItem>
          <NavLink tag={RRNavLink} to={paths.subscriptionSettings}>Subscription</NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={RRNavLink} to={paths.paymentSettings}>Payment</NavLink>
        </NavItem>
      </Nav>
    </div>
  );
}

export default AccountSettingsNav;