import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Form, FormGroup, FormFeedback, Button, Card, CardTitle, CardText, CardBody, Label, InputGroup, Spinner, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { domainActions } from '../../actions'
import { billingActions } from '../../actions';
import Checkout from '../CheckoutForm/StripeCheckout';
import { selectDomain } from 'src/redux/modules/domainReducer';
import { selectPlan } from 'src/redux/modules/planReducer';
import { selectBilling } from 'src/redux/modules/billingReducer';
import { selectPaymentAccounts } from 'src/redux/modules/memberReducer';

const RegisterDomainForm = () => {
  const domainState = useSelector(selectDomain);
  const planState = useSelector(selectPlan);
  const billingState = useSelector(selectBilling);
  const paymentAccounts = useSelector(selectPaymentAccounts);
  const account = paymentAccounts[0]

  const [formState , setFormState] = useState({
    domainName: null,
    tld: ".com"
  });

  const [submitted, setSubmitted] = useState(false);
  const [messages] = useState([]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState(formState => ({
      ...formState,
      [name]: value
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    setSubmitted(true);

    console.log("formState.domainName: ", formState.domainName, " formState.tld: ", formState.tld);
    if (formState.domainName) {
      const domainWithTld = formState.domainName + formState.tld;
      dispatch(domainActions.registerDomain(domainWithTld));
      if (planState.customDomainPlan) {
        dispatch(billingActions.updateCart(planState.customDomainPlan.id, domainWithTld));
      }
      setSubmitted(false);
    }
  }

  if (billingState.isPaymentCancelled || billingState.isPaymentSuccess ) {
    dispatch(billingActions.closePaymentModal());
  }

  const clearForm = (suggestion) => {
    const parts = suggestion.split(".")
    setFormState({
      domainName: parts[0],
      tld: "." + parts[1],
    });
  }

  const openPaymentModal = () => {
    dispatch(billingActions.openPaymentModal());
  }

  const placeOrder = () => {
    dispatch(billingActions.purchase(planState.customDomainPlan.id, domainState.domainName))
  }

  const cancel = () => {
    dispatch(billingActions.closePaymentModal());
  }

  const emptyDomainName = submitted && !formState.domainName;
  let price = 0;
  if (planState.customDomainPlan) {
    price = planState.customDomainPlan.priceInCents / 100
  }

  let delimiter = ""
  const messageBuffer = messages.map(element => {
    const composed = (<React.Fragment key={element}> {element} {delimiter}</React.Fragment>)
    delimiter = <br/>  
    return composed;
  })

  let expiration = "Expires: "
  if (account.expirationMonth && account.expirationYear)  {
    expiration = expiration.concat(account.expirationMonth).concat("/").concat(account.expirationYear);
  }

  return (
    <div>         
      <Form onSubmit={handleSubmit} type="post">
        <Card body>
          <CardTitle>
          Enter your preferred email suffix (domain name) to get started. Custom domains are ${price} yearly.
          </CardTitle>
          <CardBody>
            <FormGroup row>
              <InputGroup size="sm">
                <Input value={formState.domainName || ""} invalid={emptyDomainName} type="domainName" name="domainName" id="domainName" placeholder="memorablyuniquename" onChange={handleChange} />
                <FormFeedback invalid="{invalidEmail}">Domain name is required</FormFeedback>
                {(formState.tld && formState.tld === ".net") && <Input bsSize="sm" type="select" name="tld" id="tld" onChange={handleChange}>
                  <option key=".net" value=".net">.net</option>
                  <option key=".com" value=".com">.com</option>
                </Input>}
                {(!formState.tld || formState.tld === ".com") && <Input bsSize="sm" type="select" name="tld" id="tld" onChange={handleChange}>
                  <option key=".com" value=".com">.com</option>
                  <option key=".net" value=".net">.net</option>
                </Input>}
              </InputGroup>
            </FormGroup>
            <FormGroup row>
              {!domainState.isDomainAvailable &&
                <Button color="primary">Check Availability</Button>}
              {domainState.isDomainAvailable &&
                <Button color="secondary">Check Availability</Button>}
              &nbsp;
              {domainState.isDomainAvailable && 
                <Button color="primary" onClick={ () => openPaymentModal() }>Purchase Domain</Button>}
              {!domainState.isDomainAvailable && 
                <Button color="secondary" disabled>Purchase Domain</Button>}
              {domainState.loading && 
                  <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
              }
            </FormGroup>            
          </CardBody>
          {domainState.suggestions && domainState.suggestions.length > 0 && 
          <CardBody>
            {domainState.suggestions.map(suggestion =>
              <FormGroup key={suggestion} check disabled>
                <Label check>                        
                  <Input type="radio" name="{suggestion}" onClick={() => clearForm(suggestion)}/>{' '}{suggestion}
                </Label>
              </FormGroup>
            )}
          </CardBody>
          }
        </Card>
      </Form>
      <Modal isOpen={billingState.isPaymentModelOpen}> 
        <ModalHeader>{messageBuffer !== ""|| <span>Pay with saved credit card</span>}</ModalHeader>
        <ModalBody>     
          {billingState.isPaymentInfoRequired && <Checkout/> } 
          {!billingState.isPaymentInfoRequired && 
            <div>
              <Card body>
                <CardTitle>Credit Card Type: {account.creditCardType}</CardTitle>
                <CardText>{expiration}</CardText>
                <Button color="primary" onClick={ () => { placeOrder() }}>Place order</Button>&nbsp;
                <Button color="secondary" onClick={ () => { cancel() } }>Cancel</Button>
              </Card>
            </div>
          }
          {billingState.isBillingProcessing &&
                <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
          }
        </ModalBody>    
      </Modal>
    </div>
  );
}

export default RegisterDomainForm;