import { userConstants } from '../constants';
import { alertActions } from './';
import { userService } from '../services'
import { LimitError } from '../errors/errors';
import { paths } from '../constants'


export const virtualEmailActions = {
  getVirtualEmails, addVirtualEmail, removeVirtualEmail, getSummary, activateVirtualEmail, deactivateVirtualEmail, 
  enableEmailReply, disableEmailReply, enableAttach, disableAttach, enableAutoCreate, disableAutoCreate
};

function getVirtualEmails(isRetry) {
  return dispatch => {
    dispatch(request());
    dispatch(alertActions.clear());
    return userService.getVirtualEmails()
      .then(res => {
        dispatch(success(res))
      })
      .catch(err => {  
        if (err.message === "timeout") {
          if (isRetry) {
            dispatch(failure("Unable to fetch emails."));
            dispatch(alertActions.error("Loading failed. Please refresh."));  
          } else {
            dispatch(getVirtualEmails(true));
          }
        } else {
          dispatch(failure(err.message));
          dispatch(alertActions.error(err.message));  
        }
      })
  }
  function request() { return { type: userConstants.GET_VIRTUAL_EMAILS_REQUEST } }
  function success(items) { return { type: userConstants.GET_VIRTUAL_EMAILS_SUCCESS, items } }
  function failure(error) { return { type: userConstants.GET_VIRTUAL_EMAILS_FAILURE, error } }
}

function addVirtualEmail(username, domainId) {
  return dispatch => {
    dispatch(request());
    return userService.addVirtualEmail(username, domainId)
      .then(res => {
        dispatch(success(res))    
      }).catch(err => {
        if (err instanceof LimitError) {
          dispatch(alertActions.success("You have reached the maximum number of virtual emails. Please remove a virtual email", paths.subscriptionLandingPage, "or subscribe to a paid plan."))
        }
        dispatch(failure(err));
      })
  }
  function request() { return { type: userConstants.ADD_VIRTUAL_EMAIL_REQUEST } }
  function success(virtualEmail) { return { type: userConstants.ADD_VIRTUAL_EMAIL_SUCCESS, virtualEmail } }
  function failure(error) { return { type: userConstants.ADD_VIRTUAL_EMAIL_FAILURE, error } }
}

function removeVirtualEmail(virtualEmailId) {
  return dispatch => {
    dispatch(request());
    return userService.removeVirtualEmail(virtualEmailId)
      .then(res => {
        dispatch(success(virtualEmailId))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.REMOVE_VIRTUAL_EMAIL_REQUEST } }
  function success(virtualEmailId) { return { type: userConstants.REMOVE_VIRTUAL_EMAIL_SUCCESS, virtualEmailId } }
  function failure(error) { return { type: userConstants.REMOVE_VIRTUAL_EMAIL_FAILURE, error } }
}

function activateVirtualEmail(virtualEmailId) {
  return dispatch => {
    dispatch(request());
    return userService.activateVirtualEmail(virtualEmailId)
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.ACTIVATE_VIRTUAL_EMAIL_REQUEST } }
  function success(virtualEmail) { return { type: userConstants.ACTIVATE_VIRTUAL_EMAIL_SUCCESS, virtualEmail } }
  function failure(error) { return { type: userConstants.ACTIVATE_VIRTUAL_EMAIL_FAILURE, error } }
}

function deactivateVirtualEmail(virtualEmailId) {
  return dispatch => {
    dispatch(request());
    return userService.deactivateVirtualEmail(virtualEmailId)
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.DEACTIVATE_VIRTUAL_EMAIL_REQUEST } }
  function success(virtualEmail) { return { type: userConstants.DEACTIVATE_VIRTUAL_EMAIL_SUCCESS, virtualEmail } }
  function failure(error) { return { type: userConstants.DEACTIVATE_VIRTUAL_EMAIL_FAILURE, error } }
}

function enableEmailReply() {
  return dispatch => {
    dispatch(request());
    return userService.enableEmailReply()
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.ENABLE_REPLY_REQUEST } }
  function success(virtualEmailConfig) { return { type: userConstants.ENABLE_REPLY_SUCCESS, virtualEmailConfig } }
  function failure(error) { return { type: userConstants.ENABLE_REPLY_FAILURE, error } }
}

function disableEmailReply() {
  return dispatch => {
    dispatch(request());
    return userService.disableEmailReply()
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.DISABLE_REPLY_REQUEST } }
  function success(virtualEmailConfig) { return { type: userConstants.DISABLE_REPLY_SUCCESS, virtualEmailConfig } }
  function failure(error) { return { type: userConstants.DISABLE_REPLY_FAILURE, error } }
}

function enableAttach() {
  return dispatch => {
    dispatch(request());
    return userService.enableAttach()
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.ENABLE_ATTACH_REQUEST } }
  function success(virtualEmailConfig) { return { type: userConstants.ENABLE_ATTACH_SUCCESS, virtualEmailConfig } }
  function failure(error) { return { type: userConstants.ENABLE_ATTACH_FAILURE, error } }
}

function disableAttach() {
  return dispatch => {
    dispatch(request());
    return userService.disableAttach()
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.DISABLE_ATTACH_REQUEST } }
  function success(virtualEmailConfig) { return { type: userConstants.DISABLE_ATTACH_SUCCESS, virtualEmailConfig } }
  function failure(error) { return { type: userConstants.DISABLE_ATTACH_FAILURE, error } }
}

function enableAutoCreate() {
  return dispatch => {
    dispatch(request());
    return userService.callService('POST', '/virtualEmail/enableAutoCreate', {})
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.ENABLE_AUTO_CREATE_REQUEST } }
  function success(virtualEmailConfig) { return { type: userConstants.ENABLE_AUTO_CREATE_SUCCESS, virtualEmailConfig } }
  function failure(error) { return { type: userConstants.ENABLE_AUTO_CREATE_FAILURE, error } }
}


function disableAutoCreate() {
  return dispatch => {
    dispatch(request());
    return userService.callService('POST', '/virtualEmail/disableAutoCreate', {})
      .then(res => {
        dispatch(success(res))
      }).catch(err => {
        dispatch(failure(err.message));
      })
  }
  function request() { return { type: userConstants.DISABLE_AUTO_CREATE_REQUEST } }
  function success(virtualEmailConfig) { return { type: userConstants.DISABLE_AUTO_CREATE_SUCCESS, virtualEmailConfig } }
  function failure(error) { return { type: userConstants.DISABLE_AUTO_CREATE_FAILURE, error } }
}

function getSummary(isRetry, navigate) {

  return dispatch => {
    dispatch(request());
    return userService.callGetSummary()
      .then(res => {
        if (!res.isVerified) {
          navigate(paths.verifyEmail);
        }
        dispatch(success(res))
      })
      .catch(err => {
        if (err.message === "timeout") {
          if (isRetry) {
            dispatch(failure(err.message));
            dispatch(alertActions.error("Loading failed. Please refresh."));
          } else {
            dispatch(getSummary(true, navigate));
          }
        } else {
          dispatch(failure(err.message));
          dispatch(alertActions.error(err.message));
        }
      })
  }
  function request() { return { type: userConstants.GET_MEMBER_REQUEST } }
  function success(summary) { return { type: userConstants.GET_MEMBER_SUCCESS, summary } }
  function failure(error) { return { type: userConstants.GET_MEMBER_FAILURE, error } }
}
