import { userConstants } from '../../constants';

const initialState = {
  email: null,
}
export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        email: action.email,
        loggedIn: true
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false
      };
    case userConstants.LOGOUT:
      return {
        email: null,
        loggedIn: false
      };
    case userConstants.REGISTER_SUCCESS:
      return {
        email: action.user.email,
        loggedIn: true
      };  
    default:
      return state
  }
}

export const selectLoggingIn = (state) => state.authentication.loggingIn;
export const selectLoggedIn = (state) => state.authentication.loggedIn;
export const selectLoggedInEmail = (state) => state.authentication.email;
