import React, { useEffect, useState } from 'react';
import { Input, Form, FormGroup, Label, FormFeedback, Button, Card, CardTitle, CardBody, Spinner } from 'reactstrap';
import { userActions } from '../../actions';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectEmailVerify } from 'src/redux/modules/emailVerifyReducer';
import { selectLoggedInEmail } from 'src/redux/modules/authReducer';

const VerifyEmailForm = () => {

  const emailVerifyState = useSelector(selectEmailVerify);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [token, setToken] = useState('');
  const loggedInEmail = useSelector(selectLoggedInEmail);
  const navigate = useNavigate();

  useEffect(() => {

    let queryString = window.location.search;

    if (queryString[0] === '?') {
      queryString = queryString.slice(1)
    }

    queryString.split('&').forEach(queryPart => {
      const nameValue = queryPart.split('=');

      if (nameValue[0] === 'code') {
        const token = nameValue[1];
        setToken(token);
        setSubmitted(true);
        dispatch(userActions.verifyToken(loggedInEmail, token, navigate)); 
      }
    }
    )}, [dispatch, loggedInEmail, navigate]);

  const handleChange = (event) => {
    const { value } = event.target;
    setToken(value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (token) {
      dispatch(userActions.verifyToken(loggedInEmail, token, navigate));
    }
  }

  const handleResend = (event) => {
    event.preventDefault();
    dispatch(userActions.sendEmailConfirmToken(loggedInEmail));
  }

  const emptySubmission = submitted && !token;
  const invalid = emptySubmission || emailVerifyState.mismatch

  return (
    <div>
      <Card body>
        <CardTitle>Please check your email for the verification code, and enter it below.</CardTitle>
        <CardBody>
          <Form onSubmit={handleSubmit} type="post">
            <FormGroup row>
              <Label for="token">Code</Label>
              <Input invalid={invalid} name="token" id="token" value={token} onChange={handleChange} />
              <FormFeedback invalid="{invalid}">Invalid code, please double-check the code in the email.</FormFeedback>
            </FormGroup>
            <FormGroup row>
              <Button color="primary">Confirm Email</Button>
              {emailVerifyState.loading &&
                <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
              }
            </FormGroup>
            <FormGroup row>
              <Button onClick={handleResend} color="link"><span className="defaultFont">No code received or code not working? Click to resend.</span></Button>
            </FormGroup>
          </Form>
        </CardBody>
        
      </Card>
    </div>
  );
}

export default VerifyEmailForm;