import React from 'react';
import { Alert, Card, CardBody } from 'reactstrap';

const SubscriptionSetupElement = () => {
  return (
    <div>         
      <Card body>
        <CardBody>
          <Alert color="success">
            <h4 className="alert-heading">Subscription setup.</h4>
            <p>
              Thank you, your payment has been received and your account has now been upgraded.
            </p>
          </Alert>
        </CardBody>
      </Card>
    </div>
  );
}


export default SubscriptionSetupElement;