import { alertConstants } from '../constants';

export const alertActions = {
  success,
  error,
  clear
};

function success(message, link, linkText) {
  return { type: alertConstants.SUCCESS, message, link, linkText };
}

function error(message) {
  return { type: alertConstants.ERROR, message };
}

function clear() {
  return { type: alertConstants.CLEAR };
}