import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import { Container, Row, Col } from 'reactstrap';

const Login = () => {
  return (
    <div>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={10} sm={8} md={8} lg={8} xl={8}>
            <LoginForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;