const invalidPassword = "Invalid password. Must be at least six characters with at least one non-alphabetical.";
const unexpectedError =  "Unexpected error.";
const alreadyRegistered = "Email already registered."

export function deriveErrorMessage(err) {
  let message = null;
  if (!err) {
    message = unexpectedError;
  } else if (err.code && err.code === "UsernameExistsException") {
    message = alreadyRegistered;
  } else if (err.code && err.code === "InvalidPasswordException") {
    message = invalidPassword;
  } else if (err.code && err.code === "InvalidParameterException") {
    if (err.message.indexOf("password' failed to satisfy constraint") > -1) {
      message = invalidPassword;
    } else {
      message = err.message;
    }
  } else if (err.code && err.code === "UnexpectedLambdaException") {
    message = unexpectedError;
  } else if (err.message) {
    message = err.message;
  } else {
    message = err.toString();
  }
  return message;
}